import { Store, CommitOptions, DispatchOptions, Module } from "vuex";
import { RootState } from "@/store/interfaces";
import { SupportModuleState as SupportState, supportState } from "./state";
import { SupportMutation, supportMutations } from "./mutations";
import { SupportAction, supportAction } from "./actions";
import { SupportGetters, supportGetters } from "./getters";

export type SupportModule = {
  namespaced?: boolean;
  state?: SupportState;
  mutations?: SupportMutation;
  actions?: SupportAction;
  getters?: SupportGetters;
};

export type SupportModuleState = SupportState;

export type SupportStore<S = SupportState> = Omit<
  Store<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<
    K extends keyof SupportMutation,
    P extends Parameters<SupportMutation[K]>[1]
  >(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<SupportMutation[K]>;
} & {
  dispatch<
    K extends keyof SupportAction,
    P extends Parameters<SupportAction[K]>[1]
  >(
    key: K,
    payload?: P,
    options?: DispatchOptions
  ): ReturnType<SupportAction[K]>;
} & {
  getters: {
    [K in keyof SupportGetters]: ReturnType<SupportGetters[K]>;
  };
};

export const supportStore: Module<SupportState, RootState> & SupportModule = {
  namespaced: true,
  state: supportState,
  mutations: supportMutations,
  actions: supportAction,
  getters: supportGetters,
};

import { Status } from "@/types";
import { IssueDetails } from "@/views/Issues/types";

//declare state
export type IssuesModuleState = {
  status: Status;
  issuesError: string;
  issues: Array<IssueDetails>;
  issueInfo: IssueDetails;
};

//set state
export const issuesState: IssuesModuleState = {
  status: Status.NORMAL,
  issuesError: "",
  issues: [] as Array<IssueDetails>,
  issueInfo: {} as IssueDetails,
};

class IssuesState implements IssuesModuleState {
  public status = Status.NORMAL;

  public issuesError = "";

  public issues = [] as Array<IssueDetails>;

  public issueInfo = {} as IssueDetails;
}

export default IssuesState;

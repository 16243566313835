import { LGA, NigerianState, Status } from "@/types";
import {
  LoanBalance,
  ProductsRepaymentResponse,
  RepaymentResponse,
  Repayments,
  Transactions,
} from "@/views/Payment/types";
import { QuoteRequestResponse } from "@/views/Quote/types";

//declare state
export type TransactionModuleState = {
  status: Status;
  transactionError: string;
  transactions: Array<Transactions>;
  repayments: Array<Repayments>;
  products: ProductsRepaymentResponse;
  loanBalance: LoanBalance;
};

//set state
export const transactionState: TransactionModuleState = {
  status: Status.NORMAL,
  transactionError: "",
  transactions: [] as Array<Transactions>,
  repayments: [] as Array<Repayments>,
  products: {} as ProductsRepaymentResponse,
  loanBalance: {} as LoanBalance,
};

class TransactionState implements TransactionModuleState {
  public status = Status.NORMAL;

  public transactionError = "";

  public transactions = [] as Array<Transactions>;

  public repayments = [] as Array<Repayments>;

  public products = {} as ProductsRepaymentResponse;

  public loanBalance = {} as LoanBalance; 
}

export default TransactionState;

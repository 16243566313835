
import { defineComponent } from "vue";
import BackButton from "@/components/ui/BackButton.vue";
// import ContentView from "@/views/Dashboard/ContentView.vue";

export default defineComponent({
  name: "PasswordLayout",

  props: {
    pageTitle: { default: "Reset password", type: String },
  },

  components: {
    BackButton,
    // ContentView,
  },

  methods: {
    goBack() {
      // this.$emit("go-back");
      this.$router.back();
    },
  },
});


import { defineComponent } from "vue";
import AppLogo from "@/components/ui/AppLogo.vue";
import BackButton from "@/components/ui/BackButton.vue";
import ContentView from "@/views/Dashboard/ContentView.vue";
// import { useRouter } from "vue-router";

//Declare Vue Router
// const router = useRouter();

export default defineComponent({
  name: "AuthLayout",

  components: {
    AppLogo,
    BackButton,
    ContentView,
  },

  methods: {
    goBack() {
      // this.$emit("go-back");
      this.$router.back();
    },
  },
});

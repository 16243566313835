import { GetterTree } from "vuex";
import { SupportModuleState } from "./state";
import { RootState } from "@/store/interfaces";

export enum SupportGetterTypes {
  GET_ACTIVE_QUOTES = "GET_ACTIVE_QUOTES",
}

export interface SupportGetters {
  [SupportGetterTypes.GET_ACTIVE_QUOTES](state: SupportModuleState): any;
}

export const supportGetters: GetterTree<SupportModuleState, RootState> &
  SupportGetters = {
  [SupportGetterTypes.GET_ACTIVE_QUOTES](state: SupportModuleState): any {
    return state.status;
  },
};

import axios, {
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosError,
} from "axios";
import { useAuthStore } from "@/store";

import SessionStorageService from "./sessionStorage";
import { AuthActionTypes } from "@/store/modules/auth/actions";
import { AxiosInternalConfig, RefreshTokenInfo } from "@/types";
import AuthenticationService from "./AuthenticationService";
import { useRoute, useRouter } from "vue-router";
import { AuthMutationTypes } from "@/store/modules/auth/mutations";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "text/plain",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = SessionStorageService.getItem("token");

    if (token) {
      config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
  },

  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalConfig = error.config as AxiosInternalConfig;
    originalConfig._retry = false;

    if (error.response) {
      const decodedUser = SessionStorageService.decodeToken();

      if (error.response.status === 401 && !originalConfig?._retry) {
        originalConfig._retry = true;

        try {
          const payload: RefreshTokenInfo = {
            userId: decodedUser.jfk,
            refreshToken: decodedUser.lkh,
          };
          const refresh = await axiosInstance.post(
            "ClientUser/RefreshUserToken",
            payload
          );

          if (refresh?.data?.token) {
            SessionStorageService.setItem("token", refresh.data.token);
            (
              error.config as InternalAxiosRequestConfig
            ).headers.Authorization = `Bearer ${refresh?.data?.token}`;
          }

          return new Promise((resolve, reject) => {
            axiosInstance
              .request(originalConfig)
              .then((response) => {
                resolve(response);
              })
              .catch((e) => {
                AuthenticationService.logoutUser();
                reject(e);
              });
          });
        } catch (_error) {
          AuthenticationService.logoutUser();
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;


import { Status } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BlankButton",
  props: {
    disabled: { default: false, type: Boolean },
    buttonText: { default: "", type: String },
    status: { default: "NORMAL", type: String },
    className: { default: "", type: String },
    overRideButtonClass: { default: false, type: Boolean },
  },

  data() {
    return {
      $inheritAttrs: false,
    };
  },

  computed: {
    loadingState(): boolean {
      return this.disabled || this.status === Status.LOADING;
    },

    buttonIsDisabled(): boolean {
      return this.disabled || this.status === Status.LOADING;
    },
  },

  methods: {
    handleClick() {
      this.$emit("button-clicked");
    },
  },
});

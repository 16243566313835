import { MutationTree } from "vuex";
import { UtilityModuleState } from "./state";
import SessionStorageService from "@/services/sessionStorage";
import { useRouter } from "vue-router";
import { Status } from "@/types";
import {
  BiometricJobRequest
} from "@/views/Dashboard/types";

export enum UtilityMutationTypes {
  // GET_BIOMETRIC_REQUEST
  GET_BIOMETRIC_REQUEST_LOADING = "GET_BIOMETRIC_REQUEST_LOADING",
  GET_BIOMETRIC_REQUEST_SUCCESS = "GET_BIOMETRIC_REQUEST_SUCCESS",
  GET_BIOMETRIC_REQUEST_ERROR = "GET_BIOMETRIC_REQUEST_ERROR",

  // SUBMIT_BIOMETRIC_KYC
  SUBMIT_BIOMETRIC_KYC_LOADING = "SUBMIT_BIOMETRIC_KYC_LOADING",
  SUBMIT_BIOMETRIC_KYC_SUCCESS = "SUBMIT_BIOMETRIC_KYC_SUCCESS",
  SUBMIT_BIOMETRIC_KYC_ERROR = "SUBMIT_BIOMETRIC_KYC_ERROR",

  //SUBMIT_BIOMETRIC_STATUS
  SUBMIT_BIOMETRIC_STATUS_LOADING = "SUBMIT_BIOMETRIC_STATUS_LOADING",
  SUBMIT_BIOMETRIC_STATUS_SUCCESS = "SUBMIT_BIOMETRIC_STATUS_SUCCESS",
  SUBMIT_BIOMETRIC_STATUS_ERROR = "SUBMIT_BIOMETRIC_STATUS_ERROR", 

  // UPDATE KYC 
  UPDATE_KYC_LOADING = "UPDATE_KYC_LOADING",
  UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS",
  UPDATE_KYC_ERROR = "UPDATE_KYC_ERROR",
}

export interface UtilityMutation {
  // GET BIO REQUEST
  [UtilityMutationTypes.GET_BIOMETRIC_REQUEST_LOADING](
    state: UtilityModuleState,
    payload: any
  ): void;
  [UtilityMutationTypes.GET_BIOMETRIC_REQUEST_SUCCESS](
    state: UtilityModuleState,
    payload: any
  ): void;
  [UtilityMutationTypes.GET_BIOMETRIC_REQUEST_ERROR](
    state: UtilityModuleState,
    payload: any
  ): void;

  // GET BIO REQUEST
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void;
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_SUCCESS](
    state: UtilityModuleState,
    payload: Status
  ): void;
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_ERROR](
    state: UtilityModuleState,
    payload: Status
  ): void;

  // SUBMIT_BIOMETRIC_STATUS
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void;
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_SUCCESS](
    state: UtilityModuleState,
    payload: Status
  ): void;
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_ERROR](
    state: UtilityModuleState,
    payload: Status
  ): void;

  // UPDATE KYC 
  [UtilityMutationTypes.UPDATE_KYC_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void;
  [UtilityMutationTypes.UPDATE_KYC_SUCCESS](
    state: UtilityModuleState,
    payload: Status
  ): void;
  [UtilityMutationTypes.UPDATE_KYC_ERROR](
    state: UtilityModuleState,
    payload: Status
  ): void;
}

export const utilityMutations: MutationTree<UtilityModuleState> &
  UtilityMutation = {
  // Get BIO REQUEST
  [UtilityMutationTypes.GET_BIOMETRIC_REQUEST_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [UtilityMutationTypes.GET_BIOMETRIC_REQUEST_ERROR](
    state: UtilityModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.biometricError = error;
  },
  [UtilityMutationTypes.GET_BIOMETRIC_REQUEST_SUCCESS](
    state: UtilityModuleState,
    payload: BiometricJobRequest
  ): void {
    state.status = Status.SUCCESS;
    state.biometricJobRequest = payload;
  },

  // SUBMIT BIO REQUEST
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_ERROR](
    state: UtilityModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.biometricError = error;
  },
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_SUCCESS](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = Status.SUCCESS;
  },

  // SUBMIT_BIOMETRIC_STATUS
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_ERROR](
    state: UtilityModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.biometricError = error;
  },
  [UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_SUCCESS](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = Status.SUCCESS;
  },

  // UPDATE KYC
  [UtilityMutationTypes.UPDATE_KYC_LOADING](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = payload;
  },
  [UtilityMutationTypes.UPDATE_KYC_ERROR](
    state: UtilityModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.biometricError = error;
  },
  [UtilityMutationTypes.UPDATE_KYC_SUCCESS](
    state: UtilityModuleState,
    payload: Status
  ): void {
    state.status = Status.SUCCESS;
  },
};

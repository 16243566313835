import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: [
      _ctx.overRideButtonClass ? '' : 'app-primary-btn',
      `${_ctx.className} form-control`,
    ],
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["prevent"])),
    disabled: _ctx.buttonIsDisabled
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default"),
    _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
  ], 16, _hoisted_1))
}
import { Store, CommitOptions, DispatchOptions, Module } from "vuex";
import { RootState } from "@/store/interfaces";
import { IssuesModuleState as IssuesState, issuesState } from "./state";
import { IssuesMutation, issuesMutations } from "./mutations";
import { IssuesAction, issuesAction } from "./actions";
import { IssuesGetters, issuesGetters } from "./getters";

export type TransactionModule = {
  namespaced?: boolean;
  state?: IssuesState;
  mutations?: IssuesMutation;
  actions?: IssuesAction;
  getters?: IssuesGetters;
};

export type IssuesModuleState = IssuesState;

export type IssuesStore<S = IssuesState> = Omit<
  Store<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<
    K extends keyof IssuesMutation,
    P extends Parameters<IssuesMutation[K]>[1]
  >(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<IssuesMutation[K]>;
} & {
  dispatch<
    K extends keyof IssuesAction,
    P extends Parameters<IssuesAction[K]>[1]
  >(
    key: K,
    payload?: P,
    options?: DispatchOptions
  ): ReturnType<IssuesAction[K]>;
} & {
  getters: {
    [K in keyof IssuesGetters]: ReturnType<IssuesGetters[K]>;
  };
};

export const issuesStore: Module<IssuesState, RootState> & TransactionModule = {
  namespaced: true,
  state: issuesState,
  mutations: issuesMutations,
  actions: issuesAction,
  getters: issuesGetters,
};

import { LGA, NigerianState, Status } from "@/types";
import {
  EmployeeLists,
  FinancialInstitutions,
  ProductComponentResponse,
  QuoteRequestResponse,
  RepaymentRanges,
  UserKYC,
  WarrantyDetails,
  WebProoductResponse,
} from "@/views/Quote/types";
import {
  NextRepayment,
  ProductsResponse,
  Quote,
  QuoteRequestProduct,
  QuoteResponseRequest,
  SupportStaff,
  QuoteRequest,
} from "@/views/Dashboard/types";

//declare state
export type QuoteStates = {
  status: Status;
  quoteError: string;
  createdQuote: QuoteRequestResponse;
  quoteResponse: QuoteResponseRequest;
  productsResponse: Array<ProductsResponse>;
  nigerianStates: Array<NigerianState>;
  lga: Array<LGA>;
  nextRepayment: NextRepayment;
  warranty: WarrantyDetails;
  employees: Array<EmployeeLists>;
  productComponentCat: Array<ProductComponentResponse>;
  financialInstitutions: Array<FinancialInstitutions>;
  repaymentRanges: Array<RepaymentRanges>;
  userKYC?: UserKYC;
  webProducts: Array<WebProoductResponse>;
};

//set state
export const quoteState: QuoteStates = {
  status: Status.NORMAL,
  quoteError: "",
  createdQuote: {
    status: false,
    message: "",
    data: {
      createdBy: "",
      dateCreated: "2023-05-30T15:53:40.844Z",
      modifiedBy: "",
      dateModified: "2023-05-30T15:53:40.844Z",
      deletedBy: "",
      dateDeleted: "2023-05-30T15:53:40.844Z",
      id: "",
      tenantId: "",
      userId: "",
      address: "",
      landmark: "",
      noOfTv: 0,
      noOfFan: 0,
      buildingType: 0,
      noOfBedrooms: 0,
      noOfLights: 0,
      includeRefrigeratorEquiptment: true,
      includeFreezerEquiptment: true,
      includeAirConditionEquiptment: true,
      includeOtherEquiptment: true,
      inverterUsageHoursPerDay: 0,
      hoursOfElectricPowerPerDay: 0,
      hasGenerator: true,
      generatorCapacity: 0,
      havePreferredLocation: true,
      preferredLocationDescription: "",
      addressRequiresAccessCode: true,
      accessCodeContactPersonName: "",
      accessCodeContactPersonPhone: "",
      turnAroundTime: "",
      quoteRequestStatus: 0,
      referenceNo: "",
    },
  },
  quoteResponse: {
    status: false,
    message: "",
    quoteRequest: {} as QuoteRequest,
    employer: {} as EmployeeLists,

    quote: {} as Quote,
    changeLog: [
      {
        changeDate: "",
        changeLog: [
          {
            name: "",
            value: "",
          },
        ],
      },
    ],
    quoteRequestProduct: {} as QuoteRequestProduct,
    quoteRequestProductComponents: [],
    supportStaff: {} as SupportStaff,
    defaultSupportStaff: {} as SupportStaff,
    transactions: [],
  },
  productsResponse: [],
  nigerianStates: [],
  lga: [],
  nextRepayment: {} as NextRepayment,
  warranty: {
    duration: 0,
    startDate: "",
    endDate: "",
    monthsLeft: 0,
    daysLeft: 0,
    status: "",
  },
  employees: [] as Array<EmployeeLists>,
  productComponentCat: [] as ProductComponentResponse[],
  financialInstitutions: [] as Array<FinancialInstitutions>,
  repaymentRanges: [] as Array<RepaymentRanges>,
  webProducts: [] as Array<WebProoductResponse>,
  userKYC: {} as UserKYC,
};

class QuoteState implements QuoteStates {
  public status = Status.NORMAL;

  public quoteError = "";

  public createdQuote: QuoteRequestResponse = {
    status: false,
    message: "",
    data: {
      createdBy: "",
      dateCreated: "2023-05-30T15:53:40.844Z",
      modifiedBy: "",
      dateModified: "2023-05-30T15:53:40.844Z",
      deletedBy: "",
      dateDeleted: "2023-05-30T15:53:40.844Z",
      id: "",
      tenantId: "",
      userId: "",
      address: "",
      landmark: "",
      noOfTv: 0,
      noOfFan: 0,
      buildingType: 0,
      noOfBedrooms: 0,
      noOfLights: 0,
      includeRefrigeratorEquiptment: true,
      includeFreezerEquiptment: true,
      includeAirConditionEquiptment: true,
      includeOtherEquiptment: true,
      inverterUsageHoursPerDay: 0,
      hoursOfElectricPowerPerDay: 0,
      hasGenerator: true,
      generatorCapacity: 0,
      havePreferredLocation: true,
      preferredLocationDescription: "",
      addressRequiresAccessCode: true,
      accessCodeContactPersonName: "",
      accessCodeContactPersonPhone: "",
      turnAroundTime: "",
      quoteRequestStatus: 0,
      referenceNo: "",
    },
  };

  public quoteResponse: QuoteResponseRequest = {
    status: false,
    message: "",
    quoteRequest: {} as QuoteRequest,
    quote: {} as Quote,
    employer: {} as EmployeeLists,
    changeLog: [
      {
        changeDate: "",
        changeLog: [
          {
            name: "",
            value: "",
          },
        ],
      },
    ],
    quoteRequestProduct: {} as QuoteRequestProduct,
    quoteRequestProductComponents: [],
    supportStaff: {} as SupportStaff,
    defaultSupportStaff: {} as SupportStaff,
    transactions: [],
  };

  public productsResponse: Array<ProductsResponse> = [];

  public nigerianStates: Array<NigerianState> = [];

  public lga: Array<LGA> = [];

  public nextRepayment = {} as NextRepayment;

  public warranty: WarrantyDetails = {
    duration: 0,
    startDate: "",
    endDate: "",
    monthsLeft: 0,
    daysLeft: 0,
    status: "",
  };

  public employees = [] as Array<EmployeeLists>;

  public productComponentCat = [] as ProductComponentResponse[];

  public financialInstitutions = [] as FinancialInstitutions[];

  public repaymentRanges = [] as RepaymentRanges[];

  public webProducts = [] as WebProoductResponse[];
}

export default QuoteState;

import { GetterTree } from "vuex";
import { IssuesModuleState } from "./state";
import { RootState } from "@/store/interfaces";
import { NigerianState } from "@/types";

export enum IssuesGetterTypes {
  GET_COUNT = "GET_COUNT",
}

export interface IssuesGetters {
  [IssuesGetterTypes.GET_COUNT](state: IssuesModuleState): any;
}

export const issuesGetters: GetterTree<IssuesModuleState, RootState> &
  IssuesGetters = {
  [IssuesGetterTypes.GET_COUNT](state: IssuesModuleState): any {
    return state.status;
  },
};

import { Status, UserInfo } from "@/types";
import { CordinatorDashboardReport, CordinatorReferredCustomerDetails, CordinatorReferredCustomerList, SalesBreakdown, SalesReferal, SalesTeamDashboardReport, ScheduleResponse, SchedulesResponse } from "@/views/Admin/types";
import { QuoteResponseRequest } from "@/views/Dashboard/types";
import { AdminIssueDetails, IssueDetails } from "@/views/Issues/types";
import {
  ProductEntity,
  Products,
  SupportQuoteResponse,
} from "@/views/Support/types";

//declare state
export type SupportModuleState = {
  status: Status;
  supportError: string;
  activeQuotes: Array<SupportQuoteResponse>;
  products: Array<Products>;
  productEntity: ProductEntity;
  issues: Array<AdminIssueDetails>;
  issue: IssueDetails;
  installerQuotes: Array<any>;
  supportUsers: Array<UserInfo>;
  monitorSchedules: Array<SchedulesResponse>;
  monitoringSchedule: ScheduleResponse;
  salesTeamDashboardReport: SalesTeamDashboardReport;
  salesReferal: Array<SalesReferal>;
  salesBreakdown: SalesBreakdown;
  salesAllQuotes: Array<QuoteResponseRequest>;
  cordinatorDashboardReport: CordinatorDashboardReport;
  cordinatorRefferdLists: Array<CordinatorReferredCustomerList>;
  cordinatorReferredDetails: CordinatorReferredCustomerDetails;
  recentRefferdLists: Array<CordinatorReferredCustomerList>;
};

//set state
export const supportState: SupportModuleState = {
  status: Status.NORMAL,
  supportError: "",
  activeQuotes: [] as Array<SupportQuoteResponse>,
  products: [] as Array<Products>,
  productEntity: {} as ProductEntity,
  issues: [] as Array<AdminIssueDetails>,
  issue: {} as IssueDetails,
  installerQuotes: [],
  supportUsers: [] as Array<UserInfo>,
  monitorSchedules: [] as Array<SchedulesResponse>,
  monitoringSchedule: {} as ScheduleResponse,
  salesTeamDashboardReport: {} as SalesTeamDashboardReport,
  salesReferal: [] as Array<SalesReferal>,
  salesBreakdown: {} as SalesBreakdown,
  salesAllQuotes: [] as Array<QuoteResponseRequest>,
  cordinatorDashboardReport: {} as CordinatorDashboardReport,
  cordinatorRefferdLists: [] as Array<CordinatorReferredCustomerDetails>,
  recentRefferdLists: [] as Array<CordinatorReferredCustomerList>,
  cordinatorReferredDetails: {} as CordinatorReferredCustomerDetails,
};

class SupportState implements SupportModuleState {
  public status = Status.NORMAL;

  public supportError = "";

  public activeQuotes = [] as Array<SupportQuoteResponse>;

  public products = [] as Array<Products>;

  public productEntity = {} as ProductEntity;

  public issues = [] as Array<AdminIssueDetails>;

  public issue = {} as IssueDetails;

  public installerQuotes = [];

  public supportUsers = [] as Array<UserInfo>;

  public monitorSchedules = [] as Array<SchedulesResponse>;

  public monitoringSchedule = {} as ScheduleResponse;

  public salesTeamDashboardReport = {} as SalesTeamDashboardReport;

  public salesReferal = [] as Array<SalesReferal>;

  public salesBreakdown = {} as SalesBreakdown;

  public salesAllQuotes = [] as QuoteResponseRequest[];

  public cordinatorDashboardReport = {} as CordinatorDashboardReport;

  public cordinatorRefferdLists = [] as Array<CordinatorReferredCustomerDetails>;

  public recentRefferdLists = [] as Array<CordinatorReferredCustomerList>;

  public cordinatorReferredDetails = {} as CordinatorReferredCustomerDetails;
}

export default SupportState;


import { defineComponent } from "vue";
import BackButton from "@/components/ui/BackButton.vue";
import DynamicComponentComposable from "@/composables/dynamicComponents";
// import ContentView from "@/views/Dashboard/ContentView.vue";

export default defineComponent({
  name: "PaymentLayout",

  props: {
    pageTitle: { default: "Reset password", type: String },
  },

  components: {
    BackButton,
    // ContentView,
  },

  methods: {
    goBack() {
      // this.$emit("go-back");
      // this.handlePreviousSection();
      this.$router.back();
    },
  },

  setup() {
    const { activeComponentIndex, handleNextSection, handlePreviousSection } =
      DynamicComponentComposable();

    return {
      activeComponentIndex,
      handleNextSection,
      handlePreviousSection,
    };
  },
});

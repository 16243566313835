import { ActionTree, ActionContext } from "vuex";
import { UtilityModuleState, utilityState } from "./state";
import { UtilityMutationTypes, UtilityMutation } from "./mutations";
import { RootState } from "@/store/interfaces";
import { State } from "./types";
import axiosInstance from "@/services/axios";
import { Status } from "@/types";
import { BiometricRequest, IdentityPayload } from "@/views/Dashboard/types";

export enum UtilityActionTypes {
  GET_BIOMETRIC_REQUEST = "GET_BIOMETRIC_REQUEST",
  SUBMIT_BIOMETRIC_KYC = "SUBMIT_BIOMETRIC_KYC",
  SUBMIT_BIOMETRIC_STATUS = "SUBMIT_BIOMETRIC_STATUS",
  UPDATE_KYC = "UPDATE_KYC",
}

type AugmentedUtilityActionContext = {
  commit<K extends keyof UtilityMutation>(
    key: K,
    payload: Parameters<UtilityMutation[K]>[1]
  ): ReturnType<UtilityMutation[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface UtilityAction {
  // GET BIOMETRIC REQUEST
  [UtilityActionTypes.GET_BIOMETRIC_REQUEST](
    { commit }: AugmentedUtilityActionContext,
    payload: string
  ): Promise<any>;

  // SUBMIT BIOMETRIC REQUEST
  [UtilityActionTypes.SUBMIT_BIOMETRIC_KYC](
    { commit }: AugmentedUtilityActionContext,
    biometricReuqest: BiometricRequest
  ): Promise<any>;

  // SUBMIT BIOMETRIC JOB STATUS
  [UtilityActionTypes.SUBMIT_BIOMETRIC_STATUS](
    { commit }: AugmentedUtilityActionContext,
    payload: any
  ): Promise<any>;

  // UPDATE KYC
  [UtilityActionTypes.UPDATE_KYC](
    { commit }: AugmentedUtilityActionContext,
    payload: IdentityPayload
  ): Promise<any>;
}

export const utilityAction: ActionTree<UtilityModuleState, RootState> &
  UtilityAction = {
  // GET BIO REQUEST
  [UtilityActionTypes.GET_BIOMETRIC_REQUEST]({ commit }, payload: string): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        UtilityMutationTypes.GET_BIOMETRIC_REQUEST_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`SmileId/BiometricKYCJobRequest?quoteRequestId=${payload}`).then(
        (response: any) => {
          commit(UtilityMutationTypes.GET_BIOMETRIC_REQUEST_SUCCESS, response);
          utilityState.status = Status.SUCCESS;
          utilityState.biometricJobRequest = response.data;
          resolve(response);
        },
        (error: any) => {
          commit(
            UtilityMutationTypes.GET_BIOMETRIC_REQUEST_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //SUBMIT BIO KYC
  [UtilityActionTypes.SUBMIT_BIOMETRIC_KYC](
    { commit },
    payload: BiometricRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_LOADING, Status.LOADING);
      axiosInstance.post("SmileId/BiometricKYCPostZipFile", payload).then(
        (response: any) => {
          commit(UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_SUCCESS, response);
          utilityState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(UtilityMutationTypes.SUBMIT_BIOMETRIC_KYC_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //SUBMIT BIO KYC
  [UtilityActionTypes.SUBMIT_BIOMETRIC_STATUS](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_LOADING,
        Status.LOADING
      );
      axiosInstance.post("SmileId/BiometricKYCJobStatus", payload).then(
        (response: any) => {
          commit(
            UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_SUCCESS,
            response
          );
          utilityState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(
            UtilityMutationTypes.SUBMIT_BIOMETRIC_STATUS_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  [UtilityActionTypes.UPDATE_KYC](
    { commit },
    payload: IdentityPayload
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(UtilityMutationTypes.UPDATE_KYC_LOADING, Status.LOADING);
      axiosInstance.post("SmileId/UpdateKyc", payload).then(
        (response: any) => {
          commit(UtilityMutationTypes.UPDATE_KYC_SUCCESS, response);
          utilityState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(UtilityMutationTypes.UPDATE_KYC_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },
};

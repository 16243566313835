import { ActionTree, ActionContext } from "vuex";
import { QuoteStates, quoteState } from "./state";
import { QuoteMutationTypes, QuoteMutation } from "./mutations";
import { RootState } from "@/store/interfaces";
import { State } from "./types";
import axiosInstance from "@/services/axios";
import SessionStorageService from "@/services/sessionStorage";
import {
  QuoteProductRequest,
  QuoteRequest,
  QuoteRequestResponse,
  SalesQuoteProductRequest,
} from "@/views/Quote/types";
import { Status } from "@/types";
import {
  EquityPayment,
  FinInstitutionQuote,
  ManualEquityPayment,
} from "@/views/Dashboard/types";

export enum QuoteActionTypes {
  CREATE_QUOTE = "CREATE_QUOTE",
  UPDATE_QUOTE = "UPDATE_QUOTE",
  GET_QUOTE = "GET_QUOTE",
  GET_PRODUCTS = "GET_PRODUCTS",
  GET_WEB_PRODUCTS = "GET_WEB_PRODUCTS",
  CREATE_QUOTE_WITH_PRODUCT = "CREATE_QUOTE_WITH_PRODUCT",
  GET_STATES = "GET_STATES",
  GET_LGA = "GET_LGA",
  SAVE_QUOTE_PAYMENT = "SAVE_QUOTE_PAYMENT",
  SAVE_REPAYMENT = "SAVE_REPAYMENT",
  SAVE_PAYOFF_REPAYMENT = "SAVE_PAYOFF_REPAYMENT",
  CONFIRM_FULFILMENT_DATE = "CONFIRM_FULFILMENT_DATE",
  CONFIRM_INSTALLATION = "CONFIRM_INSTALLATION",
  GET_NEXT_REPAYMENT = "GET_NEXT_REPAYMENT",
  UPDATE_LOCATION_DATA = "UPDATE_LOCATION_DATA",
  GET_WARRANTY = "GET_WARRANTY",
  REFRESH_QUOTE = "REFRESH_QUOTE",
  GET_EMPLOYEES = "GET_EMPLOYEES",
  SAVE_MANUAL_QUOTE_PAYMENT = "SAVE_MANUAL_QUOTE_PAYMENT",
  GET_INSTALLATION_COMPONENTS_BY_CATEGORY = "GET_INSTALLATION_COMPONENTS_BY_CATEGORY",
  GET_FINANCIAL_INSTITUTIONS = "GET_FINANCIAL_INSTITUTIONS",
  CREATE_FINANCIAL_QUOTE = "CREATE_FINANCIAL_QUOTE",

  GET_QUOTE_REPAYMENTS_RANGE = "GET_QUOTE_REPAYMENTS_RANGE",
  UPDATE_QUOTE_EQUITY_PERCENTAGE = "UPDATE_QUOTE_EQUITY_PERCENTAGE",

  CREATE_SAMPLE_QUOTE_REQUEST_WITH_PRODUCT = "CREATE_SAMPLE_QUOTE_REQUEST_WITH_PRODUCT",
  CREATE_SALES_QUOTE_WITH_PRODUCT = "CREATE_SALES_QUOTE_WITH_PRODUCT",
}

type AugmentedQuoteActionContext = {
  commit<K extends keyof QuoteMutation>(
    key: K,
    payload: Parameters<QuoteMutation[K]>[1]
  ): ReturnType<QuoteMutation[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface QuoteAction {
  //POST QUOTE REQUEST
  [QuoteActionTypes.CREATE_QUOTE](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: QuoteRequest
  ): Promise<any>;

  //REFRESH QUOTE REQUEST
  [QuoteActionTypes.REFRESH_QUOTE](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: any
  ): Promise<any>;

  //UPDATE QUOTE REQUEST
  [QuoteActionTypes.UPDATE_QUOTE](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: QuoteRequest
  ): Promise<any>;

  //GET QUOTE REQUEST
  [QuoteActionTypes.GET_QUOTE](
    { commit }: AugmentedQuoteActionContext,
    id: string
  ): Promise<any>;

  //GET AUTO PRODUCTS
  [QuoteActionTypes.GET_PRODUCTS]({
    commit,
  }: AugmentedQuoteActionContext): Promise<any>;

  //GET AUTO PRODUCTS
  [QuoteActionTypes.GET_WEB_PRODUCTS]({
    commit,
  }: AugmentedQuoteActionContext): Promise<any>;

  //CREATE QUOTE REQUEST WITH PRODUCT
  [QuoteActionTypes.CREATE_QUOTE_WITH_PRODUCT](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: QuoteProductRequest
  ): Promise<any>;

  // CREATE_SALES_QUOTE_WITH_PRODUCT
  [QuoteActionTypes.CREATE_SALES_QUOTE_WITH_PRODUCT](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: SalesQuoteProductRequest
  ): Promise<any>;

  //GET STATES
  [QuoteActionTypes.GET_STATES]({
    commit,
  }: AugmentedQuoteActionContext): Promise<any>;

  //GET LGA
  [QuoteActionTypes.GET_LGA](
    { commit }: AugmentedQuoteActionContext,
    stateId: string
  ): Promise<any>;

  // SAVE QUOTE PAYMENT
  [QuoteActionTypes.SAVE_QUOTE_PAYMENT](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: EquityPayment
  ): Promise<any>;

  // SAVE REPAYMENT
  [QuoteActionTypes.SAVE_REPAYMENT](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: EquityPayment
  ): Promise<any>;

  // SAVE REPAYMENT
  [QuoteActionTypes.SAVE_PAYOFF_REPAYMENT](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: EquityPayment
  ): Promise<any>;

  // CONFIRM FULFILMENT DATE
  [QuoteActionTypes.CONFIRM_FULFILMENT_DATE](
    { commit }: AugmentedQuoteActionContext,
    payload: any
  ): Promise<any>;

  // CONFIRM INSTALLATION
  [QuoteActionTypes.CONFIRM_INSTALLATION](
    { commit }: AugmentedQuoteActionContext,
    payload: any
  ): Promise<any>;

  // GET NEXT REPAYMENT
  [QuoteActionTypes.GET_NEXT_REPAYMENT](
    { commit }: AugmentedQuoteActionContext,
    payload: any
  ): Promise<any>;

  //Update QUOTE LOCATION
  [QuoteActionTypes.UPDATE_LOCATION_DATA](
    { commit }: AugmentedQuoteActionContext,
    payload: any
  ): Promise<any>;

  // GET WARRANTY
  [QuoteActionTypes.GET_WARRANTY](
    { commit }: AugmentedQuoteActionContext,
    payload: string
  ): Promise<any>;

  // GET EMPLOYESS
  [QuoteActionTypes.GET_EMPLOYEES](
    { commit }: AugmentedQuoteActionContext,
    payload: string
  ): Promise<any>;

  // GET FINANCIAL INSTITUTIONS
  [QuoteActionTypes.GET_FINANCIAL_INSTITUTIONS](
    { commit }: AugmentedQuoteActionContext,
    payload: string
  ): Promise<any>;

  // CREATE FINANCIAL INSTITUTION QUOTE
  [QuoteActionTypes.CREATE_FINANCIAL_QUOTE](
    { commit }: AugmentedQuoteActionContext,
    payload: FinInstitutionQuote
  ): Promise<any>;

  //GET PRODUCT COMPONENTS BY CATEGORY
  [QuoteActionTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY]({
    commit,
  }: AugmentedQuoteActionContext): Promise<any>;

  //GET QUOTE REPAYMENTS RANGE
  [QuoteActionTypes.GET_QUOTE_REPAYMENTS_RANGE](
    { commit }: AugmentedQuoteActionContext,
    quoteId: string
  ): Promise<any>;

  //UPDATE QUOTE EQUITY PERCENTAGE
  [QuoteActionTypes.UPDATE_QUOTE_EQUITY_PERCENTAGE](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: any
  ): Promise<any>;

  //CREATE_SAMPLE_QUOTE_REQUEST_WITH_PRODUCT
  [QuoteActionTypes.CREATE_SAMPLE_QUOTE_REQUEST_WITH_PRODUCT](
    { commit }: AugmentedQuoteActionContext,
    quoteRequest: any
  ): Promise<any>;
}

export const quoteActions: ActionTree<QuoteStates, RootState> & QuoteAction = {
  // CREATE QUOTE
  [QuoteActionTypes.CREATE_QUOTE](
    { commit },
    quoteRequest: QuoteRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.CREATE_QUOTE_LOADING];
      [QuoteMutationTypes.CREATE_QUOTE_SUCCESS];
      axiosInstance.post("Quote/CreateQuoteRequest", quoteRequest).then(
        (response: any) => {
          [QuoteMutationTypes.CREATE_QUOTE_SUCCESS, response];
          quoteState.status = Status.SUCCESS;
          quoteState.createdQuote = response.data;
          resolve(response);
        },
        (error: any) => {
          [QuoteMutationTypes.CREATE_QUOTE_ERROR];
          reject(error);
        }
      );
    });
  },

  // REFRESH QUOTE
  [QuoteActionTypes.REFRESH_QUOTE](
    { commit },
    quoteRequest: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.REFRESH_QUOTE_LOADING, Status.LOADING);
      axiosInstance.post("Quote/RefreshQuote", quoteRequest).then(
        (response: any) => {
          commit(QuoteMutationTypes.REFRESH_QUOTE_SUCCESS, response);
          quoteState.status = Status.SUCCESS;
          quoteState.createdQuote = response.data;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.REFRESH_QUOTE_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // UPDATE QUOTE
  [QuoteActionTypes.UPDATE_QUOTE](
    { commit },
    quoteRequest: QuoteRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.UPDATE_QUOTE_LOADING];
      [QuoteMutationTypes.UPDATE_QUOTE_SUCCESS];
      axiosInstance.post("Quote/UpdateQuoteRequest", quoteRequest).then(
        (response: any) => {
          [QuoteMutationTypes.UPDATE_QUOTE_SUCCESS, response];
          quoteState.status = Status.SUCCESS;
          quoteState.createdQuote = response.data;
          resolve(response);
        },
        (error: any) => {
          [QuoteMutationTypes.UPDATE_QUOTE_ERROR];
          reject(error);
        }
      );
    });
  },

  // GET QUOTE REQUEST
  [QuoteActionTypes.GET_QUOTE]({ commit }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.GET_QUOTE_LOADING];
      [QuoteMutationTypes.GET_QUOTE_SUCCESS];
      axiosInstance.get(`Quote/GetQuoteRequest?id=${id}`).then(
        (response: any) => {
          [QuoteMutationTypes.GET_QUOTE_SUCCESS, response];
          quoteState.status = Status.SUCCESS;
          quoteState.quoteResponse = response?.data;
          resolve(response);
        },
        (error: any) => {
          [QuoteMutationTypes.GET_QUOTE_ERROR];
          reject(error);
        }
      );
    });
  },

  // GET PRODUCTS
  [QuoteActionTypes.GET_PRODUCTS]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.GET_PRODUCTS_LOADING];
      [QuoteMutationTypes.GET_PRODUCTS_SUCCESS];
      axiosInstance.get(`Product/GetProducts`).then(
        (response: any) => {
          [QuoteMutationTypes.GET_PRODUCTS_SUCCESS, response];
          quoteState.status = Status.SUCCESS;
          quoteState.productsResponse = response.data.data;
          resolve(response);
        },
        (error: any) => {
          [QuoteMutationTypes.GET_PRODUCTS_ERROR];
          reject(error);
        }
      );
    });
  },

  // GET PRODUCTS
  [QuoteActionTypes.GET_WEB_PRODUCTS]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.GET_PRODUCTS_LOADING];
      [QuoteMutationTypes.GET_PRODUCTS_SUCCESS];
      axiosInstance
        .get(`Product/GetWebProducts?key=usdsfv9hdvv9090d90u09sdvj09`)
        .then(
          (response: any) => {
            [QuoteMutationTypes.GET_PRODUCTS_SUCCESS, response];
            quoteState.status = Status.SUCCESS;
            quoteState.webProducts = response.data;
            resolve(response);
          },
          (error: any) => {
            [QuoteMutationTypes.GET_PRODUCTS_ERROR];
            reject(error);
          }
        );
    });
  },

  // CREATE QUOTE REQUEST WITH PRODUCT
  [QuoteActionTypes.CREATE_QUOTE_WITH_PRODUCT](
    { commit },
    quoteRequest: QuoteProductRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.CREATE_QUOTE_LOADING];
      [QuoteMutationTypes.CREATE_QUOTE_SUCCESS];
      axiosInstance
        .post("Quote/CreateQuoteRequestWithProduct", quoteRequest)
        .then(
          (response: any) => {
            [QuoteMutationTypes.CREATE_QUOTE_SUCCESS, response];
            quoteState.status = Status.SUCCESS;
            quoteState.createdQuote = response.data;
            resolve(response);
          },
          (error: any) => {
            [QuoteMutationTypes.CREATE_QUOTE_ERROR];
            reject(error);
          }
        );
    });
  },

  // Create sales quote product
  [QuoteActionTypes.CREATE_SALES_QUOTE_WITH_PRODUCT](
    { commit },
    quoteRequest: SalesQuoteProductRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      [QuoteMutationTypes.CREATE_QUOTE_LOADING];
      [QuoteMutationTypes.CREATE_QUOTE_SUCCESS];
      axiosInstance
        .post("Quote/CreateSalesQuoteRequestWithProduct", quoteRequest)
        .then(
          (response: any) => {
            [QuoteMutationTypes.CREATE_QUOTE_SUCCESS, response];
            quoteState.status = Status.SUCCESS;
            quoteState.createdQuote = response.data;
            resolve(response);
          },
          (error: any) => {
            [QuoteMutationTypes.CREATE_QUOTE_ERROR];
            reject(error);
          }
        );
    });
  },

  // GET STATEs
  [QuoteActionTypes.GET_STATES]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.GET_STATES_LOADING, Status.LOADING);
      axiosInstance.get(`Location/GetStates`).then(
        (response: any) => {
          commit(QuoteMutationTypes.GET_STATES_SUCCESS, response.data.states);
          quoteState.status = Status.SUCCESS;
          quoteState.nigerianStates = response.data.states;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.GET_STATES_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET LGA
  [QuoteActionTypes.GET_LGA]({ commit }, stateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.GET_LGA_LOADING, Status.LOADING);
      axiosInstance.get(`Location/GetLgas?stateId=${stateId}`).then(
        (response: any) => {
          commit(QuoteMutationTypes.GET_LGA_SUCCESS, response.data.lgas);
          quoteState.status = Status.SUCCESS;
          quoteState.lga = response.data.lgas;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.GET_LGA_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // SAVE QUOTE PAYMENT
  [QuoteActionTypes.SAVE_QUOTE_PAYMENT](
    { commit },
    payload: EquityPayment
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_LOADING, Status.LOADING);
      axiosInstance.post("Transaction/SaveQuoteEquityPayment", payload).then(
        (response) => {
          commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_SUCCESS, Status.SUCCESS);
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // SAVE QUOTE PAYMENT
  [QuoteActionTypes.SAVE_MANUAL_QUOTE_PAYMENT](
    { commit },
    payload: ManualEquityPayment
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_LOADING, Status.LOADING);
      axiosInstance
        .post("Transaction/ManualSaveQuoteEquityPayment", payload)
        .then(
          (response) => {
            commit(
              QuoteMutationTypes.SAVE_QUOTE_PAYMENT_SUCCESS,
              Status.SUCCESS
            );
            quoteState.status = Status.SUCCESS;
            resolve(response);
          },
          (error: any) => {
            commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // CONFIRM FULFILMENT DATE
  [QuoteActionTypes.CONFIRM_FULFILMENT_DATE](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_LOADING,
        Status.LOADING
      );
      axiosInstance.post("Quote/RespondToFulfilmentDate", payload).then(
        (response) => {
          commit(
            QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_SUCCESS,
            Status.SUCCESS
          );
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(
            QuoteMutationTypes.CONFIRM_FULFILMENT_DATE_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // CONFIRM FULFILMENT DATE
  [QuoteActionTypes.CONFIRM_INSTALLATION](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.CONFIRM_INSTALLATION_LOADING, Status.LOADING);
      axiosInstance.post("Quote/InstallationConfirmed", payload).then(
        (response) => {
          commit(
            QuoteMutationTypes.CONFIRM_INSTALLATION_SUCCESS,
            Status.SUCCESS
          );
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.CONFIRM_INSTALLATION_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET NEXT REPAYMENT
  [QuoteActionTypes.GET_NEXT_REPAYMENT](
    { commit },
    quoteId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.GET_NEXT_REPAYMENT_LOADING, Status.LOADING);
      axiosInstance.get(`Transaction/GetNextRepayment?quoteId=${quoteId}`).then(
        (response: any) => {
          commit(QuoteMutationTypes.GET_NEXT_REPAYMENT_SUCCESS, response.data);
          quoteState.status = Status.SUCCESS;
          quoteState.nextRepayment = response.data;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.GET_NEXT_REPAYMENT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //Update quote location data
  [QuoteActionTypes.UPDATE_LOCATION_DATA](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.UPDATE_LOCATION_DATA_LOADING, Status.LOADING);
      axiosInstance.post("Quote/UpdateQuoteRequestLocationData", payload).then(
        (response: any) => {
          commit(
            QuoteMutationTypes.UPDATE_LOCATION_DATA_SUCCESS,
            Status.SUCCESS
          );
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.UPDATE_LOCATION_DATA_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET WARRANTY
  [QuoteActionTypes.GET_WARRANTY]({ commit }, quoteId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.GET_WARRANTY_LOADING, Status.LOADING);
      axiosInstance.get(`Quote/GetWarrantyData?quoteId=${quoteId}`).then(
        (response: any) => {
          commit(
            QuoteMutationTypes.GET_WARRANTY_SUCCESS,
            response.data.warranty
          );
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.GET_WARRANTY_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET EMPLOYEES
  [QuoteActionTypes.GET_EMPLOYEES]({ commit }, name: string): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.GET_EMPLOYEES_LOADING, Status.LOADING);
      axiosInstance.get(`UserEmployer/GetAllUserEmployers?name=${name}`).then(
        (response: any) => {
          commit(QuoteMutationTypes.GET_EMPLOYEES_SUCCESS, response.data.data);
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.GET_EMPLOYEES_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET FINANCIAL INSTITUTIONS
  [QuoteActionTypes.GET_FINANCIAL_INSTITUTIONS]({ commit }): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `FinancialInstitution/FinancialInstitutions?PageIndex=1&PageSize=1000`
        )
        .then(
          (response: any) => {
            commit(
              QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              QuoteMutationTypes.GET_FINANCIAL_INSTITUTIONS_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  // CREATE FINANCIAL INSTITUTION QUOTE
  [QuoteActionTypes.CREATE_FINANCIAL_QUOTE](
    { commit },
    payload: FinInstitutionQuote
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_LOADING, Status.LOADING);
      axiosInstance
        .post(
          "FinancialInstitutionQuote/CreateFinancialInstitutionQuote",
          payload
        )
        .then(
          (response) => {
            commit(
              QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_SUCCESS,
              Status.SUCCESS
            );
            quoteState.status = Status.SUCCESS;
            resolve(response);
          },
          (error: any) => {
            commit(
              QuoteMutationTypes.CREATE_FINANCIAL_QUOTE_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  // SAVE REPPAYMENT
  [QuoteActionTypes.SAVE_REPAYMENT](
    { commit },
    payload: EquityPayment
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_LOADING, Status.LOADING);
      axiosInstance.post("Transaction/SaveRepayment", payload).then(
        (response) => {
          commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_SUCCESS, Status.SUCCESS);
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // SAVE PAYOFF REPPAYMENT
  [QuoteActionTypes.SAVE_PAYOFF_REPAYMENT](
    { commit },
    payload: EquityPayment
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_LOADING, Status.LOADING);
      axiosInstance.post("Transaction/SavePayOffRepayment", payload).then(
        (response) => {
          commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_SUCCESS, Status.SUCCESS);
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.SAVE_QUOTE_PAYMENT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET INSTALLATION COMPONENTS BY CATEGORY
  [QuoteActionTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY]({
    commit,
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Product/InstallationComponentsByCategoryWithoutImage`).then(
        (response: any) => {
          commit(
            QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_SUCCESS,
            response.data.states
          );
          quoteState.status = Status.SUCCESS;
          quoteState.productComponentCat = response.data.data;
          resolve(response);
        },
        (error: any) => {
          commit(
            QuoteMutationTypes.GET_INSTALLATION_COMPONENTS_BY_CATEGORY_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //GET QUOTE REPAYMENT RANGES
  [QuoteActionTypes.GET_QUOTE_REPAYMENTS_RANGE](
    { commit },
    quoteId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(`Quote/GetQuoteRepaymentsRange?quoteId=${quoteId}`)
        .then(
          (response: any) => {
            commit(
              QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_SUCCESS,
              response.data.data
            );
            quoteState.status = Status.SUCCESS;
            resolve(response);
          },
          (error: any) => {
            commit(
              QuoteMutationTypes.GET_QUOTE_REPAYMENTS_RANGE_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  // UPDATE QUOTE EQUITY PERCENTAGE
  [QuoteActionTypes.UPDATE_QUOTE_EQUITY_PERCENTAGE](
    { commit },
    quoteRequest: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.UPDATE_QUOTE_EQUITY_LOADING, Status.LOADING);
      axiosInstance.post("Quote/UpdateQuoteEquityPecentage", quoteRequest).then(
        (response: any) => {
          commit(QuoteMutationTypes.UPDATE_QUOTE_EQUITY_SUCCESS, response);
          quoteState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(QuoteMutationTypes.UPDATE_QUOTE_EQUITY_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // CREATE SAMPLE QUOTE REQUEST WITH PRODUCT
  [QuoteActionTypes.CREATE_SAMPLE_QUOTE_REQUEST_WITH_PRODUCT](
    { commit },
    quoteRequest: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(QuoteMutationTypes.CREATE_QUOTE_LOADING, Status.LOADING);
      axiosInstance
        .post("Quote/CreateSampleQuoteRequestWithProduct", quoteRequest)
        .then(
          (response: any) => {
            commit(QuoteMutationTypes.CREATE_QUOTE_SUCCESS, response);
            quoteState.status = Status.SUCCESS;
            resolve(response);
          },
          (error: any) => {
            commit(QuoteMutationTypes.CREATE_QUOTE_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },
};

import { MutationTree } from "vuex";
import { IssuesModuleState } from "./state";
import { Status } from "@/types";
import { RepaymentResponse, Transactions } from "@/views/Payment/types";
import { IssueDetails } from "@/views/Issues/types";

export enum IssuesMutationTypes {
  // GET ISSUES
  GET_ALL_ISSUES_LOADING = "GET_ALL_ISSUES_LOADING",
  GET_ALL_ISSUES_SUCCESS = "GET_ALL_ISSUES_SUCCESS",
  GET_ALL_ISSUES_ERROR = "GET_ALL_ISSUES_ERROR",

  // POST ISSUES
  POST_COMPLAINTS_LOADING = "POST_COMPLAINTS_LOADING",
  POST_COMPLAINTS_SUCCESS = "POST_COMPLAINTS_SUCCESS",
  POST_COMPLAINTS_ERROR = "POST_COMPLAINTS_ERROR",

  //GET ISSUE BY ID
  GET_ISSUE_BY_ID_LOADING = "GET_ISSUE_BY_ID_LOADING",
  GET_ISSUE_BY_ID_SUCCESS = "GET_ISSUE_BY_ID_SUCCESS",
  GET_ISSUE_BY_ID_ERROR = "GET_ISSUE_BY_ID_ERROR",

  // CLOSE ISSUES
  CLOSE_COMPLAINTS_LOADING = "CLOSE_COMPLAINTS_LOADING",
  CLOSE_COMPLAINTS_SUCCESS = "CLOSE_COMPLAINTS_SUCCESS",
  CLOSE_COMPLAINTS_ERROR = "CLOSE_COMPLAINTS_ERROR",

  // UPLOAD SCHEDULE IMAGE
  UPLOAD_SCHEDULE_IMAGE_LOADING = "UPLOAD_SCHEDULE_IMAGE_LOADING",
  UPLOAD_SCHEDULE_IMAGE_SUCCESS = "UPLOAD_SCHEDULE_IMAGE_SUCCESS",
  UPLOAD_SCHEDULE_IMAGE_ERROR = "UPLOAD_SCHEDULE_IMAGE_ERROR",
}

export interface IssuesMutation {
  // get issues
  [IssuesMutationTypes.GET_ALL_ISSUES_LOADING](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.GET_ALL_ISSUES_SUCCESS](
    state: IssuesModuleState,
    payload: Array<IssueDetails>
  ): void;
  [IssuesMutationTypes.GET_ALL_ISSUES_ERROR](
    state: IssuesModuleState,
    payload: any
  ): void;

  // create complaints
  [IssuesMutationTypes.POST_COMPLAINTS_LOADING](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.POST_COMPLAINTS_SUCCESS](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.POST_COMPLAINTS_ERROR](
    state: IssuesModuleState,
    payload: any
  ): void;

  // get issues by id
  [IssuesMutationTypes.GET_ISSUE_BY_ID_LOADING](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.GET_ISSUE_BY_ID_SUCCESS](
    state: IssuesModuleState,
    payload: IssueDetails
  ): void;
  [IssuesMutationTypes.GET_ISSUE_BY_ID_ERROR](
    state: IssuesModuleState,
    payload: any
  ): void;

  // CLOSE Issues
  [IssuesMutationTypes.CLOSE_COMPLAINTS_LOADING](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.CLOSE_COMPLAINTS_SUCCESS](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.CLOSE_COMPLAINTS_ERROR](
    state: IssuesModuleState,
    payload: any
  ): void;

  // UPLOAD SCHEDULE IMAGE
  [IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_LOADING](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_SUCCESS](
    state: IssuesModuleState,
    payload: any
  ): void;
  [IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_ERROR](
    state: IssuesModuleState,
    payload: any
  ): void;
}

export const issuesMutations: MutationTree<IssuesModuleState> & IssuesMutation =
  {
    // Get issues
    [IssuesMutationTypes.GET_ALL_ISSUES_LOADING](
      state: IssuesModuleState,
      payload: any
    ): void {
      state.status = payload;
    },
    [IssuesMutationTypes.GET_ALL_ISSUES_ERROR](
      state: IssuesModuleState,
      error: string
    ): void {
      state.status = Status.ERROR;
      state.issuesError = error;
    },
    [IssuesMutationTypes.GET_ALL_ISSUES_SUCCESS](
      state: IssuesModuleState,
      payload: Array<IssueDetails>
    ): void {
      state.status = Status.SUCCESS;
      state.issues = payload;
    },

    // POST COMPLAINTS
    [IssuesMutationTypes.POST_COMPLAINTS_LOADING](
      state: IssuesModuleState,
      payload: any
    ): void {
      state.status = payload;
    },
    [IssuesMutationTypes.POST_COMPLAINTS_ERROR](
      state: IssuesModuleState,
      error: string
    ): void {
      state.status = Status.ERROR;
      state.issuesError = error;
    },
    [IssuesMutationTypes.POST_COMPLAINTS_SUCCESS](
      state: IssuesModuleState,
      payload: Status
    ): void {
      state.status = payload;
    },

    // Get issues by id
    [IssuesMutationTypes.GET_ISSUE_BY_ID_LOADING](
      state: IssuesModuleState,
      payload: any
    ): void {
      state.status = payload;
    },
    [IssuesMutationTypes.GET_ISSUE_BY_ID_ERROR](
      state: IssuesModuleState,
      error: string
    ): void {
      state.status = Status.ERROR;
      state.issuesError = error;
    },
    [IssuesMutationTypes.GET_ISSUE_BY_ID_SUCCESS](
      state: IssuesModuleState,
      payload: IssueDetails
    ): void {
      state.status = Status.SUCCESS;
      state.issueInfo = payload;
    },

    // CLOSE COMPLAINTS
    [IssuesMutationTypes.CLOSE_COMPLAINTS_LOADING](
      state: IssuesModuleState,
      payload: any
    ): void {
      state.status = payload;
    },
    [IssuesMutationTypes.CLOSE_COMPLAINTS_ERROR](
      state: IssuesModuleState,
      error: string
    ): void {
      state.status = Status.ERROR;
      state.issuesError = error;
    },
    [IssuesMutationTypes.CLOSE_COMPLAINTS_SUCCESS](
      state: IssuesModuleState,
      payload: Status
    ): void {
      state.status = payload;
    },

    // UPLOAD SCHEDULE IMAGE
    [IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_LOADING](
      state: IssuesModuleState,
      payload: any
    ): void {
      state.status = payload;
    },
    [IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_SUCCESS](
      state: IssuesModuleState,
      payload: any
    ): void {
      state.status = payload;
    },
    [IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_ERROR](
      state: IssuesModuleState,
      error: any
    ): void {
      state.status = Status.ERROR;
      state.issuesError = error;
    },
  };

import { ProductsResponse } from "../Dashboard/types";

export interface HouseRequest {
  address: string;
  landmark: string;
  buildingType: number;
  state: string;
  city: string;
  employerId: string;
  workEmail: string;
}

export interface PowerRequest {
  noOfBedrooms: number;
  noOfLights: number;
  noOfFan: number;
  noOfTv: number;
  generatorCapacityOthers: string;
  includeRefrigeratorEquiptment: boolean;
  includeFreezerEquiptment: boolean;
  includeAirConditionEquiptment: boolean;
  includeOtherEquiptment: boolean;
  inverterUsageHoursPerDay: number;
  hoursOfElectricPowerPerDay: number;
  hasGenerator?: boolean;
  generatorCapacity: number;
  havePreferredLocation?: boolean;
  preferredLocationDescription?: string;
  addressRequiresAccessCode?: boolean;
  accessCodeContactPersonName?: string;
  accessCodeContactPersonPhone?: string;
  turnAroundTime?: string;
}

export interface QuoteRequest {
  employerId: string;
  address: string;
  landmark: string;
  state?: string;
  city?: string;
  noOfBedrooms: number;
  noOfLights: number;
  noOfFan: number;
  noOfTv: number;
  productId?: string;
  buildingType: number;
  includeRefrigeratorEquiptment: boolean;
  generatorCapacityOthers: string;
  includeFreezerEquiptment: boolean;
  includeAirConditionEquiptment: boolean;
  includeOtherEquiptment: boolean;
  inverterUsageHoursPerDay: number;
  hoursOfElectricPowerPerDay: number;
  hasGenerator: boolean;
  generatorCapacity: number;
  havePreferredLocation: boolean;
  preferredLocationDescription: string;
  addressRequiresAccessCode: boolean;
  accessCodeContactPersonName: string;
  accessCodeContactPersonPhone: string;
  turnAroundTime?: string;
  workEmail?: string;
}

export enum QuoteStatus {
  Draft = "Draft",
  Pending = "Pending",
  Processing = "Processing",
  Changed = "Changed",
  Approved = "Approved",
}

export interface QuoteRequestResponse {
  status: boolean;
  message: string;
  data: {
    createdBy: string;
    dateCreated: string;
    modifiedBy: string;
    dateModified: string;
    deletedBy: string;
    dateDeleted: string;
    id: string;
    tenantId: string;
    userId: string;
    address: string;
    landmark: string;
    noOfBedrooms: number;
    noOfLights: number;
    noOfFan: number;
    noOfTv: number;
    buildingType: number;
    includeRefrigeratorEquiptment: boolean;
    includeFreezerEquiptment: boolean;
    includeAirConditionEquiptment: boolean;
    includeOtherEquiptment: boolean;
    inverterUsageHoursPerDay: number;
    hoursOfElectricPowerPerDay: number;
    hasGenerator: boolean;
    generatorCapacity: number;
    havePreferredLocation: boolean;
    preferredLocationDescription: string;
    addressRequiresAccessCode: boolean;
    accessCodeContactPersonName: string;
    accessCodeContactPersonPhone: string;
    turnAroundTime: string;
    quoteRequestStatus: number;
    referenceNo: string;
  };
}

export const BuildingTypeEnum = {
  Story: "Storey building",
  Bungalow: "Bungalow",
  Flat: "Block of flats",
};

export const GeneratorCapacityEnum = {
  NOKVA: "I do not have one",
  OKVA: "1 KVA",
  TKVA: "2 KVA",
  FKVA: "5 KVA",
  MTFKVA: "> 5KVA",
  OTHERS: "Others",
};

export const SelectablePackages = [
  {
    name: "Basic plan",
    amount: "#69,263",
    contribution: "Per month/12 months",
  },
  {
    name: "Pro plan",
    amount: "#139,263",
    contribution: "Per month/6 months",
  },
  {
    name: "Premium plan",
    amount: "#869,263",
    contribution: "Full payment",
  },
];

export interface QuoteProductRequest {
  address: string;
  landmark: string;
  state?: string;
  city?: string;
  productId: string;
  buldingType: number;
}

export interface SalesQuoteProductRequest {
  address: string;
  landmark: string;
  state?: string;
  city?: string;
  productId: string;
  buldingType: number;
  contributionPecentage: number;
  installationComponents: Array<string>;
  email: string;
  phoneNumber: string;
  firstname: string;
  lastname: string;
  gender: string;
  referrer: string;
}

export interface WarrantyDetails {
  duration: number;
  startDate: string;
  endDate: string;
  monthsLeft: number;
  daysLeft: number;
  status: string;
}

export interface EmployeeLists {
  createdBy: string;
  dateCreated: string;
  modifiedBy: string;
  dateModified: string;
  deletedBy: string;
  dateDeleted: string;
  id: string;
  tenantId: string;
  name: string;
  website: string;
  image?: string;
}

export interface FinancialInstitutions {
  id: string;
  createdBy?: string;
  dateCreated?: string;
  modifiedBy?: string;
  dateModified?: string;
  deletedBy?: string;
  dateDeleted?: string;
  tenantId?: string;
  name: string;
  description: string;
  bankCode: string;
  nibbsCode: string;
  email: string;
}

export interface ProductComponent {
  id: string;
  createdBy: string;
  dateCreated: string;
  modifiedBy: string;
  dateModified: string;
  deletedBy: string;
  dateDeleted: string;
  tenantId: string;
  name: string;
  specification: string;
  imageUrl: string;
  unitPrice: number;
  componentType: string;
}

export interface ProductComponentResponse {
  categoryName: string;
  isNew?: boolean;
  data: Array<ProductComponent>;
}

export interface WebProoductResponse {
  product: ProductsResponse;
  productComponents: Array<ProductComponent>;
}

export interface RepaymentRanges {
  equityPecentage: number;
  equityPayment: number;
  totalRepayment: number;
  monthlyRepayment: number;
  repaymentDuration: number;
}

export interface UserKYC {
  dateOfBirth: string;
  bvn: string;
  meansOfId: string;
  meansOfIdImage: string;
  statementOfAccount: string;
  bankName: string;
  accountNumber: string;
  accountName: string;
  dateVerified?: null;
  quoteRequestId?: null;
  isVerified?: boolean;
  isToDoVerification?: boolean;
  dateVerificationRequested?: null;
}

import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, mergeProps as _mergeProps } from "vue"
import _imports_0 from '@/assets/img/icons/support/button-spinner-icon.svg'


const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    class: [
      _ctx.overRideButtonClass ? '' : 'app-primary-btn',
      `${_ctx.className} form-control`,
    ],
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)), ["prevent"])),
    disabled: _ctx.buttonIsDisabled
  }, _ctx.$attrs), [
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.loadingState)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _imports_0,
          class: _normalizeClass(['spinner-primary', _ctx.spinnerClass])
        }, null, 2))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
  ], 16, _hoisted_1))
}
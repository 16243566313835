import { GetterTree } from "vuex";
import { UtilityModuleState } from "./state";
import { RootState } from "@/store/interfaces";

export enum UtilityGetterTypes {
  GET_COUNT = "GET_COUNT",
}

export interface UtilityGetters {
  [UtilityGetterTypes.GET_COUNT](state: UtilityModuleState): any;
}

export const utilityGetters: GetterTree<UtilityModuleState, RootState> &
  UtilityGetters = {
  [UtilityGetterTypes.GET_COUNT](state: UtilityModuleState): any {
    return state.status;
  },
};

import { Status } from "@/types";
import { BiometricJobRequest } from "@/views/Dashboard/types";

//declare state
export type UtilityModuleState = {
  status: Status;
  biometricError: string;
  biometricJobRequest: BiometricJobRequest;
};

//set state
export const utilityState: UtilityModuleState = {
  status: Status.NORMAL,
  biometricError: "",
  biometricJobRequest: {} as BiometricJobRequest,
};

class UtilityState implements UtilityModuleState {
  public status = Status.NORMAL;

  public biometricError = "";

  public biometricJobRequest = {} as BiometricJobRequest;
}

export default UtilityState;

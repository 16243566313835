import { Store, CommitOptions, DispatchOptions, Module } from "vuex";
import { RootState } from "@/store/interfaces";
import {
  TransactionModuleState as TransactionState,
  transactionState,
} from "./state";
import { TransactionMutation, transactionMutations } from "./mutations";
import { TransactionAction, transactionAction } from "./actions";
import { TransactionGetters, transactionGetters } from "./getters";

export type TransactionModule = {
  namespaced?: boolean;
  state?: TransactionState;
  mutations?: TransactionMutation;
  actions?: TransactionAction;
  getters?: TransactionGetters;
};

export type TransactionModuleState = TransactionState;

export type TransactionStore<S = TransactionState> = Omit<
  Store<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<
    K extends keyof TransactionMutation,
    P extends Parameters<TransactionMutation[K]>[1]
  >(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<TransactionMutation[K]>;
} & {
  dispatch<
    K extends keyof TransactionAction,
    P extends Parameters<TransactionAction[K]>[1]
  >(
    key: K,
    payload?: P,
    options?: DispatchOptions
  ): ReturnType<TransactionAction[K]>;
} & {
  getters: {
    [K in keyof TransactionGetters]: ReturnType<TransactionGetters[K]>;
  };
};

export const transactionStore: Module<TransactionState, RootState> &
  TransactionModule = {
  namespaced: true,
  state: transactionState,
  mutations: transactionMutations,
  actions: transactionAction,
  getters: transactionGetters,
};


import { defineComponent } from "vue";

export default defineComponent({
  name: "BackButton",

  data() {
    return {
      $inheritAttrs: false,
    };
  },

  methods: {
    handleClick() {
      this.$emit("button-clicked");
    },
  },
});

import { ActionTree, ActionContext } from "vuex";
import { IssuesModuleState, issuesState } from "./state";
import { IssuesMutationTypes, IssuesMutation } from "./mutations";
import { RootState } from "@/store/interfaces";
import { State } from "./types";
import axiosInstance from "@/services/axios";
import { Status } from "@/types";
import { PageRequest } from "@/views/Issues/types";

export enum IssuesActionTypes {
  GET_ALL_ISSUES = "GET_ALL_ISSUES",
  POST_COMPLAINTS = "POST_COMPLAINTS",
  GET_ISSUE_BY_ID = "GET_ISSUE_BY_ID",
  CLOSE_COMPLAINTS = "CLOSE_COMPLAINTS",
  UPLOAD_SCHEDULE_IMAGE = "UPLOAD_SCHEDULE_IMAGE",
}

type AugmentedIssuesActionContext = {
  commit<K extends keyof IssuesMutation>(
    key: K,
    payload: Parameters<IssuesMutation[K]>[1]
  ): ReturnType<IssuesMutation[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface IssuesAction {
  // GET ISSUES
  [IssuesActionTypes.GET_ALL_ISSUES](
    { commit }: AugmentedIssuesActionContext,
    transactionRequest: any
  ): Promise<any>;

  // POST ISSUES
  [IssuesActionTypes.POST_COMPLAINTS](
    { commit }: AugmentedIssuesActionContext,
    payload: any
  ): Promise<any>;

  // GET ISSUE BY ID
  [IssuesActionTypes.GET_ISSUE_BY_ID](
    { commit }: AugmentedIssuesActionContext,
    id: string
  ): Promise<any>;

  // CLOSE ISSUES
  [IssuesActionTypes.CLOSE_COMPLAINTS](
    { commit }: AugmentedIssuesActionContext,
    payload: any
  ): Promise<any>;

  // UPLOAD SCHEDULE IMAGE
  [IssuesActionTypes.UPLOAD_SCHEDULE_IMAGE](
    { commit }: AugmentedIssuesActionContext,
    payload: any
  ): Promise<any>;
}

export const issuesAction: ActionTree<IssuesModuleState, RootState> &
  IssuesAction = {
  // GET ISSUES
  [IssuesActionTypes.GET_ALL_ISSUES](
    { commit },
    payload: PageRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(IssuesMutationTypes.GET_ALL_ISSUES_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `MonitoringIssue/GetMainCustomersMonitoringIssue?PageIndex=${payload.PageIndex}&PageSize=${payload.PageSize}`
        )
        .then(
          (response: any) => {
            commit(IssuesMutationTypes.GET_ALL_ISSUES_SUCCESS, response);
            issuesState.status = Status.SUCCESS;
            issuesState.issues = response.data.data;
            resolve(response);
          },
          (error: any) => {
            commit(IssuesMutationTypes.GET_ALL_ISSUES_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // POST COMPLAINTS
  [IssuesActionTypes.POST_COMPLAINTS]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(IssuesMutationTypes.POST_COMPLAINTS_LOADING, Status.LOADING);
      axiosInstance.post("MonitoringIssue/CreateMonitoringIssue", payload).then(
        (response: any) => {
          commit(IssuesMutationTypes.POST_COMPLAINTS_SUCCESS, Status.SUCCESS);
          issuesState.status = Status.SUCCESS;
          resolve(response);
        },
        (error: any) => {
          commit(IssuesMutationTypes.POST_COMPLAINTS_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET ISSUE BY ID
  [IssuesActionTypes.GET_ISSUE_BY_ID]({ commit }, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(IssuesMutationTypes.GET_ISSUE_BY_ID_LOADING, Status.LOADING);
      axiosInstance
        .get(`MonitoringIssue/GetCustomerMonitoringIssueById?id=${id}`)
        .then(
          (response: any) => {
            commit(IssuesMutationTypes.GET_ISSUE_BY_ID_SUCCESS, response);
            issuesState.status = Status.SUCCESS;
            issuesState.issueInfo = response.data.data;
            resolve(response);
          },
          (error: any) => {
            commit(IssuesMutationTypes.GET_ISSUE_BY_ID_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // CLOSE COMPLAINTS
  [IssuesActionTypes.CLOSE_COMPLAINTS]({ commit }, payload: any): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(IssuesMutationTypes.CLOSE_COMPLAINTS_LOADING, Status.LOADING);
      axiosInstance
        .post("MonitoringIssue/ChangeMonitoringIssueStatus", payload)
        .then(
          (response: any) => {
            commit(
              IssuesMutationTypes.CLOSE_COMPLAINTS_SUCCESS,
              Status.SUCCESS
            );
            issuesState.status = Status.SUCCESS;
            resolve(response);
          },
          (error: any) => {
            commit(IssuesMutationTypes.CLOSE_COMPLAINTS_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // UPDATE SCHEDULE IMAGE
  [IssuesActionTypes.UPLOAD_SCHEDULE_IMAGE](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_LOADING, Status.LOADING);
      axiosInstance
        .post("MonitoringSchedule/UploadMonitoringScheduleImage", payload)
        .then(
          (response: any) => {
            commit(
              IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_SUCCESS,
              Status.SUCCESS
            );
            issuesState.status = Status.SUCCESS;
            resolve(response);
          },
          (error: any) => {
            commit(
              IssuesMutationTypes.UPLOAD_SCHEDULE_IMAGE_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },
};

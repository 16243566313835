import { Store, CommitOptions, DispatchOptions, Module } from "vuex";
import { RootState } from "@/store/interfaces";
import { UtilityModuleState as UtilityState, utilityState } from "./state";
import { UtilityMutation, utilityMutations } from "./mutations";
import { UtilityAction, utilityAction } from "./actions";
import { UtilityGetters, utilityGetters } from "./getters";

export type UtilityModule = {
  namespaced?: boolean;
  state?: UtilityState;
  mutations?: UtilityMutation;
  actions?: UtilityAction;
  getters?: UtilityGetters;
};

export type UtilityModuleState = UtilityState;

export type UtilityStore<S = UtilityState> = Omit<
  Store<S>,
  "getters" | "commit" | "dispatch"
> & {
  commit<
    K extends keyof UtilityMutation,
    P extends Parameters<UtilityMutation[K]>[1]
  >(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<UtilityMutation[K]>;
} & {
  dispatch<
    K extends keyof UtilityAction,
    P extends Parameters<UtilityAction[K]>[1]
  >(
    key: K,
    payload?: P,
    options?: DispatchOptions
  ): ReturnType<UtilityAction[K]>;
} & {
  getters: {
    [K in keyof UtilityGetters]: ReturnType<UtilityGetters[K]>;
  };
};

export const utilityStore: Module<UtilityState, RootState> & UtilityModule =
  {
    namespaced: true,
    state: utilityState,
    mutations: utilityMutations,
    actions: utilityAction,
    getters: utilityGetters,
  };

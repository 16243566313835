
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorMessage",

  props: {
    message: { default: "", type: String },
    errorLabelClass: { default: "", type: String },
    errorContainerClass: { default: "", type: String },
  },
});

import { MutationTree } from "vuex";
import { AuthenticationState } from "./state";
import {
  AuthRequestStatus,
  CreateUserInfo,
  LoginInfo,
  Status,
  UserToken,
} from "@/types";
import SessionStorageService from "@/services/sessionStorage";
import { useRouter } from "vue-router";
import { NotificationCount, Notifications } from "@/views/Dashboard/types";
import { CordinatorAgent } from "@/views/Dashboard/types";

export enum AuthMutationTypes {
  // LOGIN
  LOGIN_USER_LOADING = "LOGIN_USER_LOADING",
  LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS",
  LOGIN_USER_ERROR = "LOGIN_USER_ERROR",

  //STORE USER
  STORE_USER = "STORE_USER",

  // CREATE USER
  CREATE_CLIENT_ACCOUNT_LOADING = "CREATE_CLIENT_ACCOUNT_LOADING",
  CREATE_CLIENT_ACCOUNT_SUCCESS = "CREATE_CLIENT_ACCOUNT_SUCCESS",
  CREATE_CLIENT_ACCOUNT_ERROR = "CREATE_CLIENT_ACCOUNT_ERROR",

  // CONFIRM USER ACCOUNT
  CONFIRM_USER_ACCOUNT_LOADING = "CONFIRM_USER_ACCOUNT_LOADING",
  CONFIRM_USER_ACCOUNT_SUCCESS = "CONFIRM_USER_ACCOUNT_SUCCESS",
  CONFIRM_USER_ACCOUNT_ERROR = "CONFIRM_USER_ACCOUNT_ERROR",

  // SET PASSWORD
  SET_PASSWORD_LOADING = "SET_PASSWORD_LOADING",
  SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR",
  SET_SALES_CUSTOMER_PASSWORD_SUCCESS = "SET_SALES_CUSTOMER_PASSWORD_SUCCESS",

  // RESET PASSWORD
  RESET_PASSWORD_REQUEST_LOADING = "RESET_PASSWORD_REQUEST_LOADING",
  RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS",
  RESET_PASSWORD_REQUEST_ERROR = "RESET_PASSWORD_REQUEST_ERROR",

  // CONFIRM PASSWORD
  CONFIRM_RESET_PASSWORD_LOADING = "CONFIRM_RESET_PASSWORD_LOADING",
  CONFIRM_RESET_PASSWORD_SUCCESS = "CONFIRM_RESET_PASSWORD_SUCCESS",
  CONFIRM_RESET_PASSWORD_ERROR = "CONFIRM_RESET_PASSWORD_ERROR",

  // RESET PASSWORD
  RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR",

  // CHANGE PASSWORD
  CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING",
  CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR",

  //RESEND OTP
  RESEND_OTP_LOADING = "RESEND_OTP_LOADING",
  RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS",
  RESEND_OTP_ERROR = "RESEND_OTP_ERROR",

  //SEND SMS OTP
  SEND_SMS_OTP_LOADING = "SEND_SMS_OTP_LOADING",
  SEND_SMS_OTP_SUCCESS = "SEND_SMS_OTP_SUCCESS",
  SEND_SMS_OTP_ERROR = "SEND_SMS_OTP_ERROR",

  //CONFIRM CLIENT USER SMS OTP
  CONFIRM_SMS_OTP_LOADING = "CONFIRM_SMS_OTP_LOADING",
  CONFIRM_SMS_OTP_SUCCESS = "CONFIRM_SMS_OTP_SUCCESS",
  CONFIRM_SMS_OTP_ERROR = "CONFIRM_SMS_OTP_ERROR",

  //REFRESH TOKEN
  REFRESH_TOKEN_LOADING = "REFRESH_TOKEN_LOADING",
  REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR",

  // UPDATE CLIENT USER
  UPDATE_CLIENT_USER_LOADING = "UPDATE_CLIENT_USER_LOADING",
  UPDATE_CLIENT_USER_SUCCESS = "UPDATE_CLIENT_USER_SUCCESS",
  UPDATE_CLIENT_USER_ERROR = "UPDATE_CLIENT_USER_ERROR",

  // CREATE SUPPOER USER
  CREATE_SUPPORT_USER_LOADING = "CREATE_SUPPORT_USER_LOADING",
  CREATE_SUPPORT_USER_SUCCESS = "CREATE_SUPPORT_USER_SUCCESS",
  CREATE_SUPPORT_USER_ERROR = "CREATE_SUPPORT_USER_ERROR",

  // GET NOTIFICATIONS
  GET_NOTIFICATIONS_LOADING = "GET_NOTIFICATIONS_LOADING",
  GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR",

  // GET NOTIFICATION COUNT
  GET_NOTIFICATIONS_COUNT_LOADING = "GET_NOTIFICATIONS_COUNT_LOADING",
  GET_NOTIFICATIONS_COUNT_SUCCESS = "GET_NOTIFICATIONS_COUNT_SUCCESS",
  GET_NOTIFICATIONS_COUNT_ERROR = "GET_NOTIFICATIONS_COUNT_ERROR",

  // COMPANY VALIDATION
  COMPANY_VALIDATION_LOADING = "COMPANY_VALIDATION_LOADING",
  COMPANY_VALIDATION_SUCCESS = "COMPANY_VALIDATION_SUCCESS",
  COMPANY_VALIDATION_ERROR = "COMPANY_VALIDATION_ERROR",

  // COMPANY VALIDATION CONFIRMATION
  COMPANY_VALIDATION_CONFIRMATION_LOADING = "COMPANY_VALIDATION_CONFIRMATION_LOADING",
  COMPANY_VALIDATION_CONFIRMATION_SUCCESS = "COMPANY_VALIDATION_CONFIRMATION_SUCCESS",
  COMPANY_VALIDATION_CONFIRMATION_ERROR = "COMPANY_VALIDATION_CONFIRMATION_ERROR",

  // GET STATE CORDINATOR'S SALES AGENT
  GET_STATE_CORDINATOR_AGENT_LOADING = "GET_STATE_CORDINATOR_AGENT_LOADING",
  GET_STATE_CORDINATOR_AGENT_SUCCESS = "GET_STATE_CORDINATOR_AGENT_SUCCESS",
  GET_STATE_CORDINATOR_AGENT_ERROR = "GET_STATE_CORDINATOR_AGENT_ERROR",
}

// LOGIN
export interface AuthenticationMutation {
  // LOGIN
  [AuthMutationTypes.LOGIN_USER_LOADING](
    state: AuthenticationState,
    payload: string
  ): void;
  [AuthMutationTypes.LOGIN_USER_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.LOGIN_USER_ERROR](
    state: AuthenticationState,
    payload: string
  ): void;

  // Create user account
  [AuthMutationTypes.CREATE_CLIENT_ACCOUNT_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.CREATE_CLIENT_ACCOUNT_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.CREATE_CLIENT_ACCOUNT_ERROR](
    state: AuthenticationState,
    payload: string
  ): void;

  // Store User
  [AuthMutationTypes.STORE_USER](
    state: AuthenticationState,
    payload: any
  ): void;

  // Confirm user account
  [AuthMutationTypes.CONFIRM_USER_ACCOUNT_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.CONFIRM_USER_ACCOUNT_SUCCESS](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.CONFIRM_USER_ACCOUNT_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  // Confirm company account
  [AuthMutationTypes.COMPANY_VALIDATION_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.COMPANY_VALIDATION_SUCCESS](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.COMPANY_VALIDATION_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  // Confirm company account VALIDATION
  [AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_SUCCESS](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  // Setup User Password
  [AuthMutationTypes.SET_PASSWORD_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.SET_PASSWORD_SUCCESS](
    state: AuthenticationState,
    payload: UserToken
  ): void;
  [AuthMutationTypes.SET_PASSWORD_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.SET_SALES_CUSTOMER_PASSWORD_SUCCESS](
    state: AuthenticationState
  ): void;

  // Reset password request
  [AuthMutationTypes.RESET_PASSWORD_REQUEST_LOADING](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.RESET_PASSWORD_REQUEST_SUCCESS](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.RESET_PASSWORD_REQUEST_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  // Reset password token request
  [AuthMutationTypes.CONFIRM_RESET_PASSWORD_LOADING](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.CONFIRM_RESET_PASSWORD_SUCCESS](
    state: AuthenticationState
  ): void;
  [AuthMutationTypes.CONFIRM_RESET_PASSWORD_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  // Reset password proper request
  [AuthMutationTypes.RESET_PASSWORD_LOADING](state: AuthenticationState): void;
  [AuthMutationTypes.RESET_PASSWORD_SUCCESS](state: AuthenticationState): void;
  [AuthMutationTypes.RESET_PASSWORD_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  //RESEND OTP
  [AuthMutationTypes.RESEND_OTP_LOADING](state: AuthenticationState): void;
  [AuthMutationTypes.RESEND_OTP_SUCCESS](state: AuthenticationState): void;
  [AuthMutationTypes.RESEND_OTP_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  //SEND OTP
  [AuthMutationTypes.SEND_SMS_OTP_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.SEND_SMS_OTP_SUCCESS](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.SEND_SMS_OTP_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  //SEND OTP
  [AuthMutationTypes.CONFIRM_SMS_OTP_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.CONFIRM_SMS_OTP_SUCCESS](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.CONFIRM_SMS_OTP_ERROR](
    state: AuthenticationState,
    payload: any
  ): void;

  // Change Password
  [AuthMutationTypes.CHANGE_PASSWORD_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.CHANGE_PASSWORD_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.CHANGE_PASSWORD_ERROR](
    state: AuthenticationState,
    payload: Status
  ): void;

  // REFRESH TOKEN
  [AuthMutationTypes.REFRESH_TOKEN_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.REFRESH_TOKEN_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.REFRESH_TOKEN_ERROR](
    state: AuthenticationState,
    payload: Status
  ): void;

  // UPDATE USER PROFILE
  [AuthMutationTypes.UPDATE_CLIENT_USER_LOADING](
    state: AuthenticationState,
    payload: string
  ): void;
  [AuthMutationTypes.UPDATE_CLIENT_USER_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.UPDATE_CLIENT_USER_ERROR](
    state: AuthenticationState,
    payload: string
  ): void;

  // Create support user account
  [AuthMutationTypes.CREATE_SUPPORT_USER_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.CREATE_SUPPORT_USER_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void;
  [AuthMutationTypes.CREATE_SUPPORT_USER_ERROR](
    state: AuthenticationState,
    payload: string
  ): void;

  // Get notifications
  [AuthMutationTypes.GET_NOTIFICATIONS_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.GET_NOTIFICATIONS_SUCCESS](
    state: AuthenticationState,
    payload: Array<Notifications>
  ): void;
  [AuthMutationTypes.GET_NOTIFICATIONS_ERROR](
    state: AuthenticationState,
    payload: string
  ): void;

  // Get notification count
  [AuthMutationTypes.GET_NOTIFICATIONS_COUNT_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.GET_NOTIFICATIONS_COUNT_SUCCESS](
    state: AuthenticationState,
    payload: NotificationCount
  ): void;
  [AuthMutationTypes.GET_NOTIFICATIONS_COUNT_ERROR](
    state: AuthenticationState,
    payload: string
  ): void;

  // Get state cordinator sales agent
  [AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void;
  [AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_SUCCESS](
    state: AuthenticationState,
    payload: CordinatorAgent
  ): void;
  [AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_ERROR](
    state: AuthenticationState,
    payload: Status
  ): void;
}

export const authMutations: MutationTree<AuthenticationState> &
  AuthenticationMutation = {
  // Login user
  [AuthMutationTypes.LOGIN_USER_LOADING](
    state: AuthenticationState,
    payload: Status.LOADING
  ): void {
    state.authRequestStatus!.login = payload;
  },
  [AuthMutationTypes.LOGIN_USER_ERROR](
    state: AuthenticationState,
    error: string
  ): void {
    state.authRequestStatus!.login = Status.ERROR;
    state.loginError = error;
  },
  [AuthMutationTypes.LOGIN_USER_SUCCESS](
    state: AuthenticationState,
    payload: UserToken
  ): void {
    state.authRequestStatus!.login = Status.SUCCESS;
    state.authenticatedUser = payload;
    SessionStorageService.setItem("token", state.authenticatedUser.token);
  },

  // Create user account
  [AuthMutationTypes.CREATE_CLIENT_ACCOUNT_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.signup = payload;
  },
  [AuthMutationTypes.CREATE_CLIENT_ACCOUNT_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.signup = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.CREATE_CLIENT_ACCOUNT_SUCCESS](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.signup = Status.SUCCESS;
  },

  //Store user
  [AuthMutationTypes.STORE_USER](
    state: AuthenticationState,
    payload: any
  ): void {
    state.storedUser = payload;
  },

  // Confirm and verify user account
  [AuthMutationTypes.CONFIRM_USER_ACCOUNT_LOADING](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.LOADING;
    state.otpError = "";
  },
  [AuthMutationTypes.CONFIRM_USER_ACCOUNT_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.CONFIRM_USER_ACCOUNT_SUCCESS](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.SUCCESS;
  },

  // Setup User Password
  [AuthMutationTypes.SET_PASSWORD_LOADING](state: AuthenticationState): void {
    state.authRequestStatus!.setPassword = Status.LOADING;
    state.otpError = "";
  },
  [AuthMutationTypes.SET_PASSWORD_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.setPassword = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.SET_PASSWORD_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void {
    state.authRequestStatus!.setPassword = Status.SUCCESS;
    state.authenticatedUser = payload;
    SessionStorageService.setItem("token", state.authenticatedUser.token);
  },
  [AuthMutationTypes.SET_SALES_CUSTOMER_PASSWORD_SUCCESS](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.setPassword = Status.SUCCESS;
  },

  //Reset password request
  [AuthMutationTypes.RESET_PASSWORD_REQUEST_LOADING](
    state: AuthenticationState
  ): void {
    state.resetPasswordRequest!.sendResetPasswordEmail = Status.LOADING;
    state.otpError = "";
  },
  [AuthMutationTypes.RESET_PASSWORD_REQUEST_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.resetPasswordRequest!.sendResetPasswordEmail = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.RESET_PASSWORD_REQUEST_SUCCESS](
    state: AuthenticationState
  ): void {
    state.resetPasswordRequest!.sendResetPasswordEmail = Status.SUCCESS;
  },

  // Reset password token request
  [AuthMutationTypes.CONFIRM_RESET_PASSWORD_LOADING](
    state: AuthenticationState
  ): void {
    state.resetPasswordRequest!.sendResetPasswordEmail = Status.LOADING;
    state.otpError = "";
  },
  [AuthMutationTypes.CONFIRM_RESET_PASSWORD_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.resetPasswordRequest!.validateOTP = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.CONFIRM_RESET_PASSWORD_SUCCESS](
    state: AuthenticationState
  ): void {
    state.resetPasswordRequest!.validateOTP = Status.SUCCESS;
  },

  // RESET PASSWORD
  [AuthMutationTypes.RESET_PASSWORD_LOADING](state: AuthenticationState): void {
    state.resetPasswordRequest!.resetPassword = Status.LOADING;
    state.otpError = "";
  },
  [AuthMutationTypes.RESET_PASSWORD_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.resetPasswordRequest!.resetPassword = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.RESET_PASSWORD_SUCCESS](state: AuthenticationState): void {
    state.resetPasswordRequest!.resetPassword = Status.SUCCESS;
  },

  // RESEND OTP
  [AuthMutationTypes.RESEND_OTP_LOADING](state: AuthenticationState): void {
    state.resetPasswordRequest!.resendOTP = Status.LOADING;
    state.otpError = "";
  },
  [AuthMutationTypes.RESEND_OTP_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.resetPasswordRequest!.resendOTP = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.RESEND_OTP_SUCCESS](state: AuthenticationState): void {
    state.resetPasswordRequest!.resendOTP = Status.SUCCESS;
  },

  // SMS OTP
  [AuthMutationTypes.SEND_SMS_OTP_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.smsOTP = payload;
  },
  [AuthMutationTypes.SEND_SMS_OTP_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.smsOTP = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.SEND_SMS_OTP_SUCCESS](state: AuthenticationState): void {
    state.authRequestStatus!.smsOTP = Status.SUCCESS;
  },

  // RESEND OTP
  [AuthMutationTypes.CONFIRM_SMS_OTP_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.resendSMSOTP = payload;
    state.otpError = "";
  },
  [AuthMutationTypes.CONFIRM_SMS_OTP_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.resendSMSOTP = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.CONFIRM_SMS_OTP_SUCCESS](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.resetPasswordRequest!.resendOTP = payload;
  },

  // CHANGE PASSWORD
  [AuthMutationTypes.CHANGE_PASSWORD_LOADING](
    state: AuthenticationState,
    payload: Status.LOADING
  ): void {
    state.authRequestStatus!.changePassword = payload;
  },
  [AuthMutationTypes.CHANGE_PASSWORD_ERROR](
    state: AuthenticationState,
    error: string
  ): void {
    state.authRequestStatus!.changePassword = Status.ERROR;
    state.loginError = error;
  },
  [AuthMutationTypes.CHANGE_PASSWORD_SUCCESS](
    state: AuthenticationState,
    payload: Status.SUCCESS
  ): void {
    state.authRequestStatus!.changePassword = payload;
  },

  // REFRESH TOKEN
  [AuthMutationTypes.REFRESH_TOKEN_LOADING](
    state: AuthenticationState,
    payload: Status.LOADING
  ): void {
    state.authRequestStatus!.refreshToken = payload;
  },
  [AuthMutationTypes.REFRESH_TOKEN_ERROR](
    state: AuthenticationState,
    error: string
  ): void {
    state.authRequestStatus!.refreshToken = Status.ERROR;
    state.loginError = error;
  },
  [AuthMutationTypes.REFRESH_TOKEN_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void {
    state.authRequestStatus!.refreshToken = Status.SUCCESS;
    // state.authenticatedUser = payload;
    state.authenticatedUser.token = payload.token;
    SessionStorageService.setItem("token", state.authenticatedUser.token);
  },

  // UPDATE CLIENT PROFILE
  [AuthMutationTypes.UPDATE_CLIENT_USER_LOADING](
    state: AuthenticationState,
    payload: Status.LOADING
  ): void {
    state.authRequestStatus!.updateProfile = payload;
  },
  [AuthMutationTypes.UPDATE_CLIENT_USER_ERROR](
    state: AuthenticationState,
    error: string
  ): void {
    state.authRequestStatus!.updateProfile = Status.ERROR;
    state.loginError = error;
  },
  [AuthMutationTypes.UPDATE_CLIENT_USER_SUCCESS](
    state: AuthenticationState,
    payload: any
  ): void {
    state.authRequestStatus!.updateProfile = Status.SUCCESS;
    // payload = Status.SUCCESS;
    state.authenticatedUser.user.firstname = payload.clientUser.firstname;
    state.authenticatedUser.user.email = payload.clientUser.email;
    state.authenticatedUser.user.lastname = payload.clientUser.lastname;
    state.authenticatedUser.user.phoneNumber = payload.clientUser.phoneNumber;
    state.authenticatedUser.user.image = payload.clientUser.image;
  },

  // Create SUPPORT USER account
  [AuthMutationTypes.CREATE_SUPPORT_USER_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.createSupportUser = payload;
  },
  [AuthMutationTypes.CREATE_SUPPORT_USER_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.createSupportUser = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.CREATE_SUPPORT_USER_SUCCESS](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.createSupportUser = Status.SUCCESS;
  },

  // Get notifications
  [AuthMutationTypes.GET_NOTIFICATIONS_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.notificationsStatus = payload;
  },
  [AuthMutationTypes.GET_NOTIFICATIONS_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.notificationsStatus = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.GET_NOTIFICATIONS_SUCCESS](
    state: AuthenticationState,
    payload: Array<Notifications>
  ): void {
    state.authRequestStatus!.notificationsStatus = Status.SUCCESS;
    state.notifications = payload;
  },

  // Get notification count
  [AuthMutationTypes.GET_NOTIFICATIONS_COUNT_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.notificationsStatus = payload;
  },
  [AuthMutationTypes.GET_NOTIFICATIONS_COUNT_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.notificationsStatus = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.GET_NOTIFICATIONS_COUNT_SUCCESS](
    state: AuthenticationState,
    payload: NotificationCount
  ): void {
    state.authRequestStatus!.notificationsStatus = Status.SUCCESS;
    state.notificationCount = payload;
  },

  // SEND USER WORK EMAIL OTP
  [AuthMutationTypes.COMPANY_VALIDATION_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.confirmAccountRequest = payload;
    state.otpError = "";
  },
  [AuthMutationTypes.COMPANY_VALIDATION_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.COMPANY_VALIDATION_SUCCESS](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.SUCCESS;
  },

  // CONFIRM USER WORK OTP
  [AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_LOADING](
    state: AuthenticationState,
    payload: Status
  ): void {
    state.authRequestStatus!.confirmAccountRequest = payload;
    state.otpError = "";
  },
  [AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_ERROR](
    state: AuthenticationState,
    error: any
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.ERROR;
    state.otpError = error?.response?.data;
  },
  [AuthMutationTypes.COMPANY_VALIDATION_CONFIRMATION_SUCCESS](
    state: AuthenticationState
  ): void {
    state.authRequestStatus!.confirmAccountRequest = Status.SUCCESS;
  },

  // Get notifications
  [AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_LOADING](
    state: AuthenticationState
  ): void {
    state.status = Status.LOADING;
  },
  [AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_ERROR](
    state: AuthenticationState
  ): void {
    state.status = Status.ERROR;
    state.cordinatorAgents = {} as CordinatorAgent;
  },
  [AuthMutationTypes.GET_STATE_CORDINATOR_AGENT_SUCCESS](
    state: AuthenticationState,
    payload: CordinatorAgent
  ): void {
    state.status = Status.SUCCESS;
    state.cordinatorAgents = payload;
  },
};

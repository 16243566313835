import { GetterTree } from "vuex";
import { TransactionModuleState } from "./state";
import { RootState } from "@/store/interfaces";
import { NigerianState } from "@/types";

export enum TransactionGetterTypes {
  GET_COUNT = "GET_COUNT",
}

export interface TransactionGetters {
  [TransactionGetterTypes.GET_COUNT](state: TransactionModuleState): any;
}

export const transactionGetters: GetterTree<TransactionModuleState, RootState> &
  TransactionGetters = {
  [TransactionGetterTypes.GET_COUNT](state: TransactionModuleState): any {
    return state.status;
  },
};

import { computed, InjectionKey } from "vue";
import {
  CommitOptions,
  createStore,
  DispatchOptions,
  Store,
  mapGetters,
  createLogger,
} from "vuex";

import { useStore as vuexUseStore } from "vuex";

// AUTH
import { AuthenticationState, authStore } from "./modules/auth";
import { AuthenticationAction, AuthActionTypes } from "./modules/auth/actions";
import {
  AuthenticationMutation,
  AuthMutationTypes,
} from "./modules/auth/mutations";
import { AuthGetters, AuthGetterTypes } from "./modules/auth/getters";
// import AuthState from "./modules/auth/state";

import createdPersistedState from "vuex-persistedstate";
import { RootState } from "./interfaces";

// QUOTE
import { IQuoteState, IQuoteStore, quoteStore } from "./modules/quote";
import { QuoteAction, QuoteActionTypes } from "./modules/quote/actions";
import { QuoteMutation, QuoteMutationTypes } from "./modules/quote/mutations";
import { QuoteGetters, QuoteGetterTypes } from "./modules/quote/getters";

// TRANSACTION
import {
  TransactionModuleState,
  transactionStore,
} from "./modules/transaction";
import {
  TransactionAction,
  TransactionActionTypes,
} from "./modules/transaction/actions";
import {
  TransactionMutation,
  TransactionMutationTypes,
} from "./modules/transaction/mutations";
import {
  TransactionGetters,
  TransactionGetterTypes,
} from "./modules/transaction/getters";

// COMPLAINTS/ISSUES
import { IssuesModuleState, issuesStore } from "./modules/issues";
import { IssuesGetters, IssuesGetterTypes } from "./modules/issues/getters";
import {
  IssuesMutation,
  IssuesMutationTypes,
} from "./modules/issues/mutations";
import { IssuesAction, IssuesActionTypes } from "./modules/issues/actions";

// SUPPORT AND ADMINS
import { SupportModuleState, supportStore } from "./modules/support";
import { SupportGetters, SupportGetterTypes } from "./modules/support/getters";
import {
  SupportMutation,
  SupportMutationTypes,
} from "./modules/support/mutations";
import { SupportAction, SupportActionTypes } from "./modules/support/actions";

// ALL OTHER UTILITIES
import { UtilityModuleState, utilityStore } from "./modules/utility";
import { UtilityGetters, UtilityGetterTypes } from "./modules/utility/getters";
import {
  UtilityMutation,
  UtilityMutationTypes,
} from "./modules/utility/mutations";
import { UtilityAction, UtilityActionTypes } from "./modules/utility/actions";

export const rootStore = createStore({
  modules: {
    auth: authStore,
    quote: quoteStore,
    transaction: transactionStore,
    issues: issuesStore,
    support: supportStore,
    utility: utilityStore,
  },
  plugins: [
    createLogger(),
    createdPersistedState({
      paths: ["auth"],
    }),
  ],
});

export const rootStoreKey: InjectionKey<Store<RootState>> = Symbol();

function rootStoreToNamespacedStore<
  ActionTypes,
  Actions extends { [key: string]: any },
  MutationsTypes,
  Mutations extends { [key: string]: any },
  GetterTypes,
  Getters extends { [key: string]: any },
  StateType
>(namespace: string, store: Store<any>) {
  return {
    getters<K extends keyof Getters>(
      getterType: GetterTypes
    ): ReturnType<Getters[K]> {
      return store.getters[`${namespace}/${getterType}`];
    },
    dispatch<K extends keyof Actions>(
      payloadWithType: ActionTypes,
      payload: Parameters<Actions[K]>[1],
      options?: DispatchOptions
    ): ReturnType<Actions[K]> {
      return store.dispatch(
        `${namespace}/${payloadWithType}`,
        payload,
        options
      ) as ReturnType<Actions[K]>;
    },
    commit<K extends keyof Mutations>(
      payloadWithType: MutationsTypes,
      payload: Parameters<Mutations[K]>[1],
      options?: CommitOptions
    ): void {
      return store.commit(`${namespace}/${payloadWithType}`, payload, options);
    },
    state: store.state[namespace] as StateType,
  };
}

export function useStore() {
  const store = vuexUseStore(rootStoreKey);
  return store;
}

export function useAuthStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    AuthActionTypes,
    AuthenticationAction,
    AuthMutationTypes,
    AuthenticationMutation,
    AuthGetterTypes,
    AuthGetters,
    AuthenticationState
  >("auth", store);
}

export function useQuoteStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    QuoteActionTypes,
    QuoteAction,
    QuoteMutationTypes,
    QuoteMutation,
    QuoteGetterTypes,
    QuoteGetters,
    IQuoteState
  >("quote", store);
}

export function useTransactionStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    TransactionActionTypes,
    TransactionAction,
    TransactionMutationTypes,
    TransactionMutation,
    TransactionGetterTypes,
    TransactionGetters,
    TransactionModuleState
  >("transaction", store);
}

export function useIssuesStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    IssuesActionTypes,
    IssuesAction,
    IssuesMutationTypes,
    IssuesMutation,
    IssuesGetterTypes,
    IssuesGetters,
    IssuesModuleState
  >("issues", store);
}

export function useSupportStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    SupportActionTypes,
    SupportAction,
    SupportMutationTypes,
    SupportMutation,
    SupportGetterTypes,
    SupportGetters,
    SupportModuleState
  >("support", store);
}

export function useUtilityStore() {
  const store = vuexUseStore(rootStoreKey);
  return rootStoreToNamespacedStore<
    UtilityActionTypes,
    UtilityAction,
    UtilityMutationTypes,
    UtilityMutation,
    UtilityGetterTypes,
    UtilityGetters,
    UtilityModuleState
  >("utility", store);
}

import { ActionTree, ActionContext } from "vuex";
import { TransactionModuleState, transactionState } from "./state";
import { TransactionMutationTypes, TransactionMutation } from "./mutations";
import { RootState } from "@/store/interfaces";
import { State } from "./types";
import axiosInstance from "@/services/axios";
import { Status } from "@/types";

export enum TransactionActionTypes {
  GET_REPAYMENTS = "GET_REPAYMENTS",
  GET_TRANSACTIONS = "GET_TRANSACTIONS",
  GET_PRODUCT_REPAYMENTS = "GET_PRODUCT_REPAYMENTS",
  SET_PAYMENT_ON_DELIVERY = "SET_PAYMENT_ON_DELIVERY",
  GET_LOAN_BALANCE = "GET_LOAN_BALANCE",
}

type AugmentedTransactionActionContext = {
  commit<K extends keyof TransactionMutation>(
    key: K,
    payload: Parameters<TransactionMutation[K]>[1]
  ): ReturnType<TransactionMutation[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface TransactionAction {
  // GET REPAYMENTS
  [TransactionActionTypes.GET_TRANSACTIONS](
    { commit }: AugmentedTransactionActionContext,
    transactionRequest: any
  ): Promise<any>;
}

export const transactionAction: ActionTree<TransactionModuleState, RootState> &
  TransactionAction = {
  // GET TRANSACTIONS
  [TransactionActionTypes.GET_TRANSACTIONS](
    { commit },
    transactionRequest: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(TransactionMutationTypes.GET_TRANSACTIONS_LOADING, Status.LOADING);
      axiosInstance
        .get(`Transaction/GetTransactions?quoteId=${transactionRequest}`)
        .then(
          (response: any) => {
            commit(TransactionMutationTypes.GET_TRANSACTIONS_SUCCESS, response);
            transactionState.status = Status.SUCCESS;
            transactionState.transactions = response.data.data;
            resolve(response);
          },
          (error: any) => {
            commit(
              TransactionMutationTypes.GET_TRANSACTIONS_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  // GET REPAYMENTS
  [TransactionActionTypes.GET_REPAYMENTS](
    { commit },
    transactionRequest: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(TransactionMutationTypes.GET_REPAYMENTS_LOADING, Status.LOADING);
      axiosInstance
        .get(`Transaction/GetRepayments?quoteId=${transactionRequest}`)
        .then(
          (response: any) => {
            commit(
              TransactionMutationTypes.GET_REPAYMENTS_SUCCESS,
              response.data.data.generalRepayment
            );
            resolve(response);
          },
          (error: any) => {
            commit(TransactionMutationTypes.GET_REPAYMENTS_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // GET PRODUCT REPAYMENTS
  [TransactionActionTypes.GET_PRODUCT_REPAYMENTS](
    { commit },
    transactionRequest: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(TransactionMutationTypes.GET_REPAYMENTS_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `Transaction/GenerateProductRepayments?productId=${transactionRequest}`
        )
        .then(
          (response: any) => {
            commit(
              TransactionMutationTypes.GET_REPAYMENTS_SUCCESS,
              response.data.generalRepayment
            );
            commit(
              TransactionMutationTypes.GET_PRODUCT_REPAYMENTS_SUCCESS,
              response.data.product
            );
            resolve(response);
          },
          (error: any) => {
            commit(TransactionMutationTypes.GET_REPAYMENTS_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // SET PAYMENT ON DELIVERY
  [TransactionActionTypes.SET_PAYMENT_ON_DELIVERY](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(TransactionMutationTypes.SET_PAYMENT_LOADING, Status.LOADING);
      axiosInstance.post(`Transaction/SetPayOnDelivery`, payload).then(
        (response: any) => {
          commit(TransactionMutationTypes.SET_PAYMENT_SUCCESS, Status.SUCCESS);
          resolve(response);
        },
        (error: any) => {
          commit(TransactionMutationTypes.SET_PAYMENT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET LOAN BALANCE
  [TransactionActionTypes.GET_LOAN_BALANCE](
    { commit },
    transactionRequest: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(TransactionMutationTypes.GET_LOAN_BALANCE_LOADING, Status.LOADING);
      axiosInstance
        .get(`Transaction/GetLoanBalance?quoteId=${transactionRequest}`)
        .then(
          (response: any) => {
            // console.log(response);
            commit(
              TransactionMutationTypes.GET_LOAN_BALANCE_SUCCESS,
              response.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              TransactionMutationTypes.GET_LOAN_BALANCE_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },
};

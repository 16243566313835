import { MutationTree } from "vuex";
import { TransactionModuleState } from "./state";
import SessionStorageService from "@/services/sessionStorage";
import { useRouter } from "vue-router";
import { Status } from "@/types";
import {
  RepaymentResponse,
  Repayments,
  Transactions,
  ProductsRepaymentResponse,
  LoanBalance,
} from "@/views/Payment/types";

export enum TransactionMutationTypes {
  // GET transactions
  GET_REPAYMENTS_LOADING = "GET_REPAYMENTS_LOADING",
  GET_REPAYMENTS_SUCCESS = "GET_REPAYMENTS_SUCCESS",
  GET_REPAYMENTS_ERROR = "GET_REPAYMENTS_ERROR",

  // GET REPAYMENT
  GET_TRANSACTIONS_LOADING = "GET_TRANSACTIONS_LOADING",
  GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR",

  //GET product repayment products
  GET_PRODUCT_REPAYMENTS_SUCCESS = "GET_PRODUCT_REPAYMENTS_SUCCESS",

  //SET PAYMENT ON DELIVERY
  SET_PAYMENT_SUCCESS = "SET_PAYMENT_SUCCESS",
  SET_PAYMENT_LOADING = "SET_PAYMENT_LOADING",
  SET_PAYMENT_ERROR = "SET_PAYMENT_ERROR",

  // GET LOAN BALANCE
  GET_LOAN_BALANCE_SUCCESS = "GET_LOAN_BALANCE_SUCCESS",
  GET_LOAN_BALANCE_LOADING = "GET_LOAN_BALANCE_LOADING",
  GET_LOAN_BALANCE_ERROR = "GET_LOAN_BALANCE_ERROR",
}

export interface TransactionMutation {
  // create repayments
  [TransactionMutationTypes.GET_REPAYMENTS_LOADING](
    state: TransactionModuleState,
    payload: any
  ): void;
  [TransactionMutationTypes.GET_REPAYMENTS_SUCCESS](
    state: TransactionModuleState,
    payload: any
  ): void;
  [TransactionMutationTypes.GET_REPAYMENTS_ERROR](
    state: TransactionModuleState,
    payload: any
  ): void;

  // create quote
  [TransactionMutationTypes.GET_TRANSACTIONS_LOADING](
    state: TransactionModuleState,
    payload: any
  ): void;
  [TransactionMutationTypes.GET_TRANSACTIONS_SUCCESS](
    state: TransactionModuleState,
    payload: any
  ): void;
  [TransactionMutationTypes.GET_TRANSACTIONS_ERROR](
    state: TransactionModuleState,
    payload: any
  ): void;

  // LOAN BALANCE
  [TransactionMutationTypes.GET_LOAN_BALANCE_LOADING](
    state: TransactionModuleState,
    payload: any
  ): void;
  [TransactionMutationTypes.GET_LOAN_BALANCE_SUCCESS](
    state: TransactionModuleState,
    payload: any
  ): void;
  [TransactionMutationTypes.GET_LOAN_BALANCE_ERROR](
    state: TransactionModuleState,
    payload: any
  ): void;
}

export const transactionMutations: MutationTree<TransactionModuleState> &
  TransactionMutation = {
  // Get Repayments
  [TransactionMutationTypes.GET_REPAYMENTS_LOADING](
    state: TransactionModuleState,
    payload: any
  ): void {
    state.status = payload;
  },
  [TransactionMutationTypes.GET_REPAYMENTS_ERROR](
    state: TransactionModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.transactionError = error;
  },
  [TransactionMutationTypes.GET_REPAYMENTS_SUCCESS](
    state: TransactionModuleState,
    payload: Array<Repayments>
  ): void {
    state.status = Status.SUCCESS;
    state.repayments = payload;
  },
  [TransactionMutationTypes.GET_PRODUCT_REPAYMENTS_SUCCESS](
    state: TransactionModuleState,
    payload: ProductsRepaymentResponse
  ): void {
    state.status = Status.SUCCESS;
    state.products = payload;
  },

  // Get Transactions
  [TransactionMutationTypes.GET_TRANSACTIONS_LOADING](
    state: TransactionModuleState,
    payload: any
  ): void {
    state.status = payload;
  },
  [TransactionMutationTypes.GET_TRANSACTIONS_ERROR](
    state: TransactionModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.transactionError = error;
  },
  [TransactionMutationTypes.GET_TRANSACTIONS_SUCCESS](
    state: TransactionModuleState,
    payload: Array<Transactions>
  ): void {
    state.status = Status.SUCCESS;
    state.transactions = payload;
  },

  // GET LOAN BALANCE
  [TransactionMutationTypes.GET_LOAN_BALANCE_LOADING](
    state: TransactionModuleState,
    payload: any
  ): void {
    state.status = payload;
  },
  [TransactionMutationTypes.GET_LOAN_BALANCE_ERROR](
    state: TransactionModuleState,
    error: string
  ): void {
    state.status = Status.ERROR;
    state.transactionError = error;
  },
  [TransactionMutationTypes.GET_LOAN_BALANCE_SUCCESS](
    state: TransactionModuleState,
    payload: LoanBalance
  ): void {
    state.status = Status.SUCCESS;
    state.loanBalance = payload;
  },
};

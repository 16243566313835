import { ActionTree, ActionContext } from "vuex";
import { SupportModuleState, supportState } from "./state";
import { SupportMutationTypes, SupportMutation } from "./mutations";
import { RootState } from "@/store/interfaces";
import { State } from "./types";
import axiosInstance from "@/services/axios";
import { Status } from "@/types";
import { SupportQuoteRequest } from "@/views/Support/types";
import {
  SupportUserRequests,
  MonitorScheduleRequest,
  CordinatorPageRequest,
} from "@/views/Admin/types";

export enum SupportActionTypes {
  GET_ACTIVE_QUOTES = "GET_ACTIVE_QUOTES",
  GET_RESOLVED_QUOTES = "GET_ACTIVE_QUOTES",
  GET_CLOSED_QUOTES = "GET_CLOSED_QUOTES",

  GET_NOT_RESPONDED_QUOTES = "GET_NOT_RESPONDED_QUOTES",
  GET_AWAITING_CUSTOMER_RESPONSE_QUOTES = "GET_AWAITING_CUSTOMER_RESPONSE_QUOTES",
  GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES = "GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES",
  GET_AWAITING_INSTALLATION_QUOTES = "GET_AWAITING_INSTALLATION_QUOTES",
  GET_COMPLETED_INSTALLATION_QUOTES = "GET_COMPLETED_INSTALLATION_QUOTES",

  GET_PRODUCTS = "GET_PRODUCTS",
  GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL",

  GET_ISSUES = "GET_ISSUES",
  GET_ISSUE_DETAILS = "GET_ISSUE_DETAILS",

  GET_ACTIVE_INSTALLATIONS = "GET_ACTIVE_INSTALLATIONS",
  GET_COMPLETED_INSTALLATIONS = "GET_COMPLETED_INSTALLATIONS",

  SET_FULFILMENT_DATE = "SET_FULFILMENT_DATE",
  GET_SUPPORT_USERS = "GET_SUPPORT_USERS",
  ASSIGN_QUOTE_SUPPORT = "ASSIGN_QUOTE_SUPPORT",
  ASSIGN_QUOTE_PRODUCT = "ASSIGN_QUOTE_PRODUCT",

  CONFIRM_INSTALLATION_COMPLETE = "CONFIRM_INSTALLATION_COMPLETE",

  CLOSE_MONITORING_SCHEDULE = "CLOSE_MONITORING_SCHEDULE",

  GET_MONITORING_SCHEDULE_BY_ID = "GET_MONITORING_SCHEDULE_BY_ID",

  GET_MONITORING_SCHEDULES = "GET_MONITORING_SCHEDULES",

  UPDATE_INSTALLATION_COST = "UPDATE_INSTALLATION_COST",

  UPDATE_TRANSPORTATION_COST = "UPDATE_TRANSPORTATION_COST",

  //Sales team
  GET_SALES_TEAM_DASHBOARD_RECORD = "GET_SALES_TEAM_DASHBOARD_RECORD",
  GET_SALES_TEAM_ALL_REFERAL = "GET_SALES_TEAM_ALL_REFERAL",
  GET_SALES_TEAM_OWING_REFERAL = "GET_SALES_TEAM_OWING_REFERAL",
  GET_SALES_TEAM_NONPERFORMING_REFERAL = "GET_SALES_TEAM_NONPERFORMING_REFERAL",
  GET_SALES_TEAM_RECENT_REFERAL = "GET_SALES_TEAM_RECENT_REFERAL",
  GET_SALES_DETAILS_BY_CUSTOMER_ID = "GET_SALES_DETAILS_BY_CUSTOMER_ID",
  GET_SALES_ALL_QUOTES = "GET_SALES_ALL_QUOTES",

  //Coordinator endpoint
  GET_CORDINATOR_DASHBOARD_RECORD = "GET_CORDINATOR_DASHBOARD_RECORD",
  GET_CORDINATOR_ALL_REFERAL = "GET_CORDINATOR_ALL_REFERAL",
  GET_CORDINATOR_OTHER_REFERAL = "GET_CORDINATOR_OTHER_REFERAL",
  GET_CORDINATOR_RECENT_REFERAL = "GET_CORDINATOR_RECENT_REFERAL",
  GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID = "GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID",
  GET_SALES_ALL_QUOTES_BY_SALES_AGENT = "GET_SALES_ALL_QUOTES_BY_SALES_AGENT",
}

type AugmentedSupportActionContext = {
  commit<K extends keyof SupportMutation>(
    key: K,
    payload: Parameters<SupportMutation[K]>[1]
  ): ReturnType<SupportMutation[K]>;
} & Omit<ActionContext<State, RootState>, "commit">;

export interface SupportAction {
  // GET SUPPORT QUOTES
  [SupportActionTypes.GET_ACTIVE_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request: SupportQuoteRequest
  ): Promise<any>;

  // GET PRODUCTS
  [SupportActionTypes.GET_PRODUCTS](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET PRODUCT DETAIL
  [SupportActionTypes.GET_PRODUCT_DETAIL](
    { commit }: AugmentedSupportActionContext,
    id: string
  ): Promise<any>;

  // GET PRODUCT DETAIL
  [SupportActionTypes.GET_ISSUES](
    { commit }: AugmentedSupportActionContext,
    request: SupportQuoteRequest
  ): Promise<any>;

  // GET PRODUCT DETAIL
  [SupportActionTypes.GET_ISSUE_DETAILS](
    { commit }: AugmentedSupportActionContext,
    request: string
  ): Promise<any>;

  // GET ACTIVE INSTALLATION
  [SupportActionTypes.GET_ACTIVE_INSTALLATIONS](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // GET UNRESPONDED QUOTES
  [SupportActionTypes.GET_NOT_RESPONDED_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // GET AWAITING CUSTOMER RESPONSE QUOTES
  [SupportActionTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // GET AWAITING ENGINEER ASSIGNMENT QUOTES
  [SupportActionTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // GET AWAITING ENGINEER ASSIGNMENT QUOTES
  [SupportActionTypes.GET_AWAITING_INSTALLATION_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // GET COMPLETED INSTALLATION QUOTES
  [SupportActionTypes.GET_COMPLETED_INSTALLATION_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // GET COMPLETED INSTALLATION
  [SupportActionTypes.GET_COMPLETED_INSTALLATIONS](
    { commit }: AugmentedSupportActionContext,
    request?: string
  ): Promise<any>;

  // SET FULFILMENT DATE
  [SupportActionTypes.SET_FULFILMENT_DATE](
    { commit }: AugmentedSupportActionContext,
    request: any
  ): Promise<any>;

  // GET ALL ENGINEERS
  [SupportActionTypes.GET_SUPPORT_USERS](
    { commit }: AugmentedSupportActionContext,
    request: SupportUserRequests
  ): Promise<any>;

  // ASSIGN ENGINEER TO QUOTE
  [SupportActionTypes.ASSIGN_QUOTE_SUPPORT](
    { commit }: AugmentedSupportActionContext,
    request: any
  ): Promise<any>;

  // ASSIGN PRODUCT TO QUOTE
  [SupportActionTypes.ASSIGN_QUOTE_PRODUCT](
    { commit }: AugmentedSupportActionContext,
    request: any
  ): Promise<any>;

  // COMPLETE INSTALLATION
  [SupportActionTypes.CONFIRM_INSTALLATION_COMPLETE](
    { commit }: AugmentedSupportActionContext,
    payload: any
  ): Promise<any>;

  // CLOSE MONITORING SCHEDULE
  [SupportActionTypes.CLOSE_MONITORING_SCHEDULE](
    { commit }: AugmentedSupportActionContext,
    request: any
  ): Promise<any>;

  // UPDATE INSTALLATION COST
  [SupportActionTypes.UPDATE_INSTALLATION_COST](
    { commit }: AugmentedSupportActionContext,
    payload: any
  ): Promise<any>;

  // GET MONITORING SCHEDULES
  [SupportActionTypes.GET_MONITORING_SCHEDULES](
    { commit }: AugmentedSupportActionContext,
    request: any
  ): Promise<any>;

  // GET MONITORING SCHEDULE BY ID
  [SupportActionTypes.GET_MONITORING_SCHEDULE_BY_ID](
    { commit }: AugmentedSupportActionContext,
    request: any
  ): Promise<any>;

  // GET SALES TEAM DASHBOARD REPORT
  [SupportActionTypes.GET_SALES_TEAM_DASHBOARD_RECORD](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET SALES TEAM DASHBOARD REPORT
  [SupportActionTypes.GET_SALES_TEAM_RECENT_REFERAL](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET SALES TEAM DASHBOARD REPORT
  [SupportActionTypes.GET_SALES_TEAM_ALL_REFERAL](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET SALES DETAILS BY CUSTOMER ID
  [SupportActionTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET SALES ALL QUOTES
  [SupportActionTypes.GET_SALES_ALL_QUOTES](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET COORDINATOR DASHBOARD REPORT
  [SupportActionTypes.GET_CORDINATOR_DASHBOARD_RECORD](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET COORDINATOR RECENT REFERRAL
  [SupportActionTypes.GET_CORDINATOR_RECENT_REFERAL](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;

  // GET SALES ALL QUOTES UNDER A SALES AGENT
  [SupportActionTypes.GET_SALES_ALL_QUOTES_BY_SALES_AGENT](
    { commit }: AugmentedSupportActionContext,
    request?: any
  ): Promise<any>;
}

export const supportAction: ActionTree<SupportModuleState, RootState> &
  SupportAction = {
  // GET QUOTES
  [SupportActionTypes.GET_CLOSED_QUOTES](
    { commit },
    request: SupportQuoteRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_ACTIVE_QUOTES_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `Quote/QuoteWithStatus?PageIndex=${request.PageIndex}&PageSize=${request.PageSize}&Status=${request.Status}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_ACTIVE_QUOTES_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(SupportMutationTypes.GET_ACTIVE_QUOTES_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // GET QUOTES
  [SupportActionTypes.GET_ACTIVE_QUOTES](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_ACTIVE_QUOTES_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `Quote/ActiveQuoteRequest?PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
          // `Quote/ActiveQuote?PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_ACTIVE_QUOTES_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(SupportMutationTypes.GET_ACTIVE_QUOTES_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // GET UNRESPONDED QUOTES
  [SupportActionTypes.GET_NOT_RESPONDED_QUOTES](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetNotRespondedQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_NOT_RESPONDED_QUOTES_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // GET AWAITING CUSTOMER RESPONSE QQUOTES
  [SupportActionTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetAwaitingCustomerResponseQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_AWAITING_CUSTOMER_RESPONSE_QUOTES_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // GET AWAITING ENGINEER ASSIGNMENT QUOTES
  [SupportActionTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetAwaitingEngineerAssignmentQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_AWAITING_ENGINEER_ASSIGNMENT_QUOTES_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // GET AWAITING INSTALLATION QUOTES
  [SupportActionTypes.GET_AWAITING_INSTALLATION_QUOTES](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetAwaitingInstallationQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_AWAITING_INSTALLATION_QUOTES_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // GET COMPLETED INSTALLATION QUOTES
  [SupportActionTypes.GET_COMPLETED_INSTALLATION_QUOTES](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetCompletedInstallationQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_COMPLETED_INSTALLATION_QUOTES_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // GET PRODUCTS
  [SupportActionTypes.GET_PRODUCTS](
    { commit },
    request: SupportQuoteRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_PRODUCTS_LOADING, Status.LOADING);
      axiosInstance.get(`Product/GetProducts`).then(
        (response: any) => {
          commit(SupportMutationTypes.GET_PRODUCTS_SUCCESS, response.data.data);
          resolve(response);
        },
        (error: any) => {
          commit(SupportMutationTypes.GET_PRODUCTS_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  // GET PRODUCT DETAIL
  [SupportActionTypes.GET_PRODUCT_DETAIL](
    { commit },
    request: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_PRODUCT_DETAIL_LOADING, Status.LOADING);
      axiosInstance
        .get(`Product/GetProductWithRelatedProperties?id=${request}`)
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_PRODUCT_DETAIL_SUCCESS,
              response.data.data
            );
            // console.log(response.data.data);
            resolve(response);
          },
          (error: any) => {
            commit(SupportMutationTypes.GET_PRODUCT_DETAIL_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // GET ISSUES
  [SupportActionTypes.GET_ISSUES](
    { commit },
    request: SupportQuoteRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_ISSUES_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `MonitoringIssue/GetMonitoringIssueByStatus?PageIndex=${request.PageIndex}&PageSize=${request.PageSize}&Status=${request.Status}`
        )
        .then(
          (response: any) => {
            commit(SupportMutationTypes.GET_ISSUES_SUCCESS, response.data.data);
            resolve(response);
          },
          (error: any) => {
            commit(SupportMutationTypes.GET_ISSUES_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // GET ISSUE DETAILS
  [SupportActionTypes.GET_ISSUE_DETAILS](
    { commit },
    request: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_ISSUE_DETAILS_LOADING, Status.LOADING);
      axiosInstance
        .get(`MonitoringIssue/GetMonitoringIssueById?id=${request}`)
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_ISSUE_DETAILS_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(SupportMutationTypes.GET_ISSUE_DETAILS_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  // GET ACTIVE INSTALLATION
  [SupportActionTypes.GET_ACTIVE_INSTALLATIONS](
    { commit },
    request?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetMyActiveInstallationQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_ACTIVE_INSTALLATIONS_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  // GET COMPLETED INSTALLATIONS
  [SupportActionTypes.GET_COMPLETED_INSTALLATIONS](
    { commit },
    request?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`Quote/GetMyCompletedInstallationQuotes`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_COMPLETED_INSTALLATIONS_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //SET INSTALLATION DATE
  [SupportActionTypes.SET_FULFILMENT_DATE](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.SET_FULFILMENT_DATE_LOADING, Status.LOADING);
      axiosInstance.post("Quote/SetFulfilmentDate", payload).then(
        (response: any) => {
          commit(
            SupportMutationTypes.SET_FULFILMENT_DATE_SUCCESS,
            Status.SUCCESS
          );
          resolve(response);
        },
        (error: any) => {
          commit(SupportMutationTypes.SET_FULFILMENT_DATE_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //GET SUPPORT USERS
  [SupportActionTypes.GET_SUPPORT_USERS](
    { commit },
    request: SupportUserRequests
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_SUPPORT_USERS_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `ClientUser/GetSupportUsers?UserRole=${request.UserRole}&PageIndex=${request.PageIndex}&PageSize=${request.PageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SUPPORT_USERS_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(SupportMutationTypes.GET_SUPPORT_USERS_ERROR, Status.ERROR);
            reject(error);
          }
        );
    });
  },

  //ASSIGN ENGINEER TO QUOTE
  [SupportActionTypes.ASSIGN_QUOTE_SUPPORT](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_LOADING, Status.LOADING);
      axiosInstance.post("Quote/AssignSupportUserToQuoteRequest", payload).then(
        (response: any) => {
          commit(
            SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_SUCCESS,
            Status.SUCCESS
          );
          resolve(response);
        },
        (error: any) => {
          commit(SupportMutationTypes.ASSIGN_QUOTE_SUPPORT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //ASSIGN ENGINEER TO QUOTE
  [SupportActionTypes.ASSIGN_QUOTE_PRODUCT](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_LOADING, Status.LOADING);
      axiosInstance.post("Quote/AssignProductToQuote", payload).then(
        (response: any) => {
          commit(
            SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_SUCCESS,
            Status.SUCCESS
          );
          resolve(response);
        },
        (error: any) => {
          commit(SupportMutationTypes.ASSIGN_QUOTE_PRODUCT_ERROR, Status.ERROR);
          reject(error);
        }
      );
    });
  },

  //ASSIGN ENGINEER TO QUOTE
  [SupportActionTypes.CONFIRM_INSTALLATION_COMPLETE](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_LOADING,
        Status.LOADING
      );
      axiosInstance.post("Quote/InstallationCompletion", payload).then(
        (response: any) => {
          commit(
            SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_SUCCESS,
            Status.SUCCESS
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.CONFIRM_INSTALLATION_COMPLETE_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //UPDATE INSTALLATION COST
  [SupportActionTypes.UPDATE_INSTALLATION_COST](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.UPDATE_INSTALLATION_COST_LOADING,
        Status.LOADING
      );
      axiosInstance.post("Quote/UpdateQuoteInstallationCost", payload).then(
        (response: any) => {
          commit(
            SupportMutationTypes.UPDATE_INSTALLATION_COST_SUCCESS,
            Status.SUCCESS
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.UPDATE_INSTALLATION_COST_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //UPDATE TRANSPORTATION COST
  [SupportActionTypes.UPDATE_TRANSPORTATION_COST](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.UPDATE_TRANSPORTATION_COST_LOADING,
        Status.LOADING
      );
      axiosInstance.post("Quote/UpdateQuoteTransportationCost", payload).then(
        (response: any) => {
          commit(
            SupportMutationTypes.UPDATE_TRANSPORTATION_COST_SUCCESS,
            Status.SUCCESS
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.UPDATE_TRANSPORTATION_COST_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //CLOSE MONITORIGN SCHEDULE
  [SupportActionTypes.CLOSE_MONITORING_SCHEDULE](
    { commit },
    payload: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_LOADING,
        Status.LOADING
      );
      axiosInstance
        .post("MonitoringSchedule/CloseMonitoringSchedule", payload)
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_SUCCESS,
              Status.SUCCESS
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.CLOSE_MONITORING_SCHEDULE_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET MONITORING SCHEDULES
  [SupportActionTypes.GET_MONITORING_SCHEDULES](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_MONITORING_SCHEDULES_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `MonitoringSchedule/GetMonitoringSchedules?pageIndex=${request.pageIndex}&pageSize=${request.pageSize}&status=${request.status}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_MONITORING_SCHEDULES_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_MONITORING_SCHEDULES_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET MONITORING SCHEDULE BY ID
  [SupportActionTypes.GET_MONITORING_SCHEDULE_BY_ID](
    { commit },
    request: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `MonitoringSchedule/GetMonitoringSchedule?monitoringScheduleId=${request}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_SUCCESS,
              response.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_MONITORING_SCHEDULE_BY_ID_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  /*-----------------
    SALES TEAM DATA
  ------------------*/
  //GET SALES TEAM DASHBOARD REPORT
  [SupportActionTypes.GET_SALES_TEAM_DASHBOARD_RECORD](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`SalesTeam/GetDashBoardRecord`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_SUCCESS,
            response.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_SALES_TEAM_DASHBOARD_REPORT_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //GET SALES TEAM RECENT REFERRALS
  [SupportActionTypes.GET_SALES_TEAM_RECENT_REFERAL](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `SalesTeam/RecentReferral?StartDate=${request?.startDate}&PageIndex=${request?.pageIndex}&PageSize=${request?.pageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_RECENT_REFERAL_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET SALES TEAM RECENT REFERRALS
  [SupportActionTypes.GET_SALES_TEAM_ALL_REFERAL](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `SalesTeam/AllReferral?PageIndex=${request?.pageIndex}&PageSize=${request?.pageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET SALES TEAM OWING REFERRALS
  [SupportActionTypes.GET_SALES_TEAM_OWING_REFERAL](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `SalesTeam/OwingCustomers?PageIndex=${request?.pageIndex}&PageSize=${request?.pageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET SALES TEAM NON PERFORMING REFERRALS
  [SupportActionTypes.GET_SALES_TEAM_NONPERFORMING_REFERAL](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `SalesTeam/NonPerformingCustomers?PageIndex=${request?.pageIndex}&PageSize=${request?.pageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_TEAM_ALL_REFERAL_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET SALES DETAILS BY CUSTOMER ID
  [SupportActionTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID](
    { commit },
    request: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(`SalesTeam/ReferralDetailsByCustomerId?CustomerId=${request}`)
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_SUCCESS,
              response.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_DETAILS_BY_CUSTOMER_ID_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET SALES TEAM RECENT REFERRALS
  [SupportActionTypes.GET_SALES_ALL_QUOTES](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_SALES_ALL_QUOTES_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `SalesTeam/SalesAgentQuoteList?PageIndex=${request?.pageIndex}&PageSize=${request?.pageSize}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_ALL_QUOTES_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_ALL_QUOTES_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET COORDINATOR DASHBOARD REPORT
  [SupportActionTypes.GET_CORDINATOR_DASHBOARD_RECORD](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`SalesTeam/GetSalesTeamCommisionKpi`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_SUCCESS,
            response.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_CORDINATOR_DASHBOARD_REPORT_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //GET AGENT RECENT REFERRALS
  [SupportActionTypes.GET_CORDINATOR_RECENT_REFERAL](
    { commit },
    request: MonitorScheduleRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_LOADING,
        Status.LOADING
      );
      axiosInstance.get(`SalesTeam/GetRecentReferrals`).then(
        (response: any) => {
          commit(
            SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_SUCCESS,
            response.data.data
          );
          resolve(response);
        },
        (error: any) => {
          commit(
            SupportMutationTypes.GET_CORDINATOR_RECENT_REFERAL_ERROR,
            Status.ERROR
          );
          reject(error);
        }
      );
    });
  },

  //GET AGENT OTHER REFERRALS
  [SupportActionTypes.GET_CORDINATOR_OTHER_REFERAL](
    { commit },
    request: CordinatorPageRequest
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(
          `SalesTeam/RefferedCustomerList?FilterByPendingCommisionSettlement=${request?.FilterByPendingCommisionSettlement}&CustomerPaymentFilter=${request?.CustomerPaymentFilter}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_CORDINATOR_OTHER_REFERAL_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET SALES DETAILS BY CUSTOMER ID
  [SupportActionTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID](
    { commit },
    request: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(
        SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_LOADING,
        Status.LOADING
      );
      axiosInstance
        .get(`SalesTeam/ReferredCustomer?customerId=${request}`)
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_CORDINATOR_DETAILS_BY_CUSTOMER_ID_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },

  //GET ALL QUOTES UNDER A SALES AGENT
  [SupportActionTypes.GET_SALES_ALL_QUOTES_BY_SALES_AGENT](
    { commit },
    request: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      commit(SupportMutationTypes.GET_SALES_ALL_QUOTES_LOADING, Status.LOADING);
      axiosInstance
        .get(
          `SalesTeam/SalesAgentQuoteListBySalesAgentId?PageIndex=${request?.pageIndex}&PageSize=${request?.pageSize}&SalesAgentId=${request?.SalesAgentId}`
        )
        .then(
          (response: any) => {
            commit(
              SupportMutationTypes.GET_SALES_ALL_QUOTES_SUCCESS,
              response.data.data
            );
            resolve(response);
          },
          (error: any) => {
            commit(
              SupportMutationTypes.GET_SALES_ALL_QUOTES_ERROR,
              Status.ERROR
            );
            reject(error);
          }
        );
    });
  },
};


import { defineComponent } from "vue";
import BackButton from "@/components/ui/BackButton.vue";

export default defineComponent({
  name: "SetupLayout",

  props: ["ballStyle", "radius", "stroke", "progress"],

  data() {
    const normalizedRadius = this.radius - this.stroke;
    const circumference = normalizedRadius * 2 * Math.PI;
    return {
      normalizedRadius,
      circumference,
    };
  },

  components: {
    BackButton,
  },

  computed: {
    strokeDashoffset(): any {
      return this.circumference - (this.progress / 100) * this.circumference;
    },
  },

  methods: {
    goBack() {
      this.$emit("go-back");
      // this.$router.back();
    },
  },
});

import {
  createRouter,
  createWebHistory,
  isNavigationFailure,
  NavigationFailureType,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import SessionStorageService from "@/services/sessionStorage";
import { Role } from "@/types";
import AppLayout from "@/components/layout/dashboard/DashboardLayout.vue";
import AuthLayout from "@/components/layout/auth/AuthLayout.vue";
import PasswordLayout from "@/components/layout/password/PasswordLayout.vue";
import SetupLayout from "@/components/layout/password/SetupLayout.vue";
import PaymentLayout from "@/components/layout/dashboard/PaymentLayout.vue";
import Dashboard from "../views/Dashboard/DashboardIndex.vue";
import AuthView from "../views/auth/AuthView.vue";
import GetQuote from "../views/Quote/GetQuote.vue";
import QuoteIndex from "../views/Quote/QuoteIndex.vue";
import GetAdminRequests from "../views/Admin/Requests/RequestIndex.vue";
import RequestDetails from "../views/Admin/Requests/RequestDetails.vue";
import SalesAuthView from "../views/auth/SalesAgent/SalesAuthView.vue";
import SalesAgentDashboard from "../views/SalesAgent/Dashboard.vue";
import SalesAgentProductDetails from "../views/SalesAgent/Details.vue";
import SalesAgentProductDetailsRepayment from "../views/SalesAgent/DetailsRepayment.vue";
import CustomerAgentProductDetailsRepayment from "../views/Dashboard/Referral/DetailsRepayment.vue";
import SalesAgentReferrals from "../views/SalesAgent/AllReferrals.vue";
import AgentQuoteIndex from "../views/SalesAgent/EditQuote/QuoteIndex.vue";
import CordinatorQuoteIndex from "../views/SalesAgentCordinator/EditQuote/QuoteIndex.vue";
/*eslint-disable @typescript-eslint/no-explicit-any */
const routes: Array<RouteRecordRaw> = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: AppLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/referral-dashboard",
    name: "ReferralDashboard",
    component: () =>
      import(
        /* webpackChunkName: "ReferralDashboard" */ "../views/Dashboard/Referral/Dashboard.vue"
      ),
    meta: {
      layout: AppLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/all-referrals",
    name: "AllUserReferral",
    component: () =>
      import(
        /* webpackChunkName: "AllUserReferral" */ "../views/Dashboard/Referral/AllReferrals.vue"
      ),
    meta: {
      layout: AppLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/referral-detail/:id",
    name: "AllUserReferralDetail",
    component: () =>
      import(
        /* webpackChunkName: "AllUserReferralDetail" */ "../views/Dashboard/Referral/Details.vue"
      ),
    meta: {
      layout: AppLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/",
    name: "Overview",
    component: AuthView,
    meta: {
      allowedRoles: [Role.GUEST],
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/auth/LoginView.vue"),
    meta: {
      layout: AuthLayout,
      allowedRoles: [Role.GUEST],
    },
  },
  {
    path: "/set-password",
    name: "SupportPassword",
    component: () =>
      import(
        /* webpackChunkName: "supportPasswordChange" */ "../views/auth/SupportPassword.vue"
      ),
    meta: {
      layout: AuthLayout,
      allowedRoles: [Role.SUPPORT],
    },
  },
  {
    path: "/admin-password",
    name: "AdminPassword",
    component: () =>
      import(
        /* webpackChunkName: "adminPasswordChange" */ "../views/auth/SupportPassword.vue"
      ),
    meta: {
      layout: AuthLayout,
      allowedRoles: [Role.ADMIN],
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/auth/Registration/IndexView.vue"
      ),
    meta: {
      layout: AuthLayout,
      allowedRoles: [Role.GUEST],
    },
  },
  {
    path: "/support-register",
    name: "SupportRegister",
    component: () =>
      import(
        /* webpackChunkName: "supportRegister" */ "../views/auth/Support/IndexView.vue"
      ),
    meta: {
      layout: AuthLayout,
      allowedRoles: [Role.GUEST],
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "passwordReset" */ "../views/auth/Password/IndexView.vue"
      ),
    meta: {
      layout: PasswordLayout,
      allowedRoles: [Role.GUEST],
    },
  },
  {
    path: "/get-qoute",
    name: "GetQuote",
    component: GetQuote,
    meta: {
      layout: AppLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/start-quote",
    name: "StartQuote",
    component: QuoteIndex,
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/quote-details",
    name: "QuoteDetails",
    component: () =>
      import(
        /* webpackChunkName: "QuoteDetails" */ "../views/Quotation/QuoteDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/payment-history",
    name: "PaymentHistory",
    component: () =>
      import(
        /* webpackChunkName: "PaymentHistory" */ "../views/Quotation/PaymentHistory.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/repayment-schedule",
    name: "RepaymentSchedule",
    component: () =>
      import(
        /* webpackChunkName: "RepaymentSchedule" */ "../views/Quotation/RepaymentSchedule.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/make-payment",
    name: "MakePayment",
    component: () =>
      import(
        /* webpackChunkName: "MakePayment" */ "../views/Quotation/MakePayment.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/issues",
    name: "MonitorIssues",
    component: () =>
      import(
        /* webpackChunkName: "Issues" */ "../views/Issues/MonitorIssues.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/issue-details/:id",
    name: "IssueDetails",
    component: () =>
      import(
        /* webpackChunkName: "IssueDetails" */ "../views/Issues/IssueDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/monitor-schedule/:id",
    name: "MonitorSchedulesDetails",
    component: () =>
      import(
        /* webpackChunkName: "MonitorSchedulesDetails" */ "../views/Admin/Complaints/Active/MonitoringDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },
  {
    path: "/post-issue",
    name: "PostIssue",
    component: () =>
      import(
        /* webpackChunkName: "PostIssues" */ "../views/Issues/PostIssue.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/system-checkup",
    name: "MonitorCheckup",
    component: () =>
      import(
        /* webpackChunkName: "Issues" */ "../views/Issues/MonitoringCheckup.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/installation-details",
    name: "InstallationDetails",
    component: () =>
      import(
        /* webpackChunkName: "InstallationDetails" */ "../views/Installation/InstallationDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/install-details",
    name: "InstallDetails",
    component: () =>
      import(
        /* webpackChunkName: "InstallDetails" */ "../views/Installation/InstallDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/warranty-details",
    name: "WarrantyDetails",
    component: () =>
      import(
        /* webpackChunkName: "WarrantyDetails" */ "../views/Quotation/WarrantyDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/warranty-terms",
    name: "WarrantyTerms",
    component: () =>
      import(
        /* webpackChunkName: "WarrantyTerms" */ "../views/Quotation/WarrantyTerms.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/payment",
    name: "Payment",
    component: () =>
      import(
        /* webpackChunkName: "PaymentIndex" */ "../views/Payment/PaymentIndex.vue"
      ),
    meta: {
      layout: PaymentLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/outright-payment",
    name: "OutrightPayment",
    component: () =>
      import(
        /* webpackChunkName: "OutrightPaymentIndex" */ "../views/Payment/Outright/PaymentIndex.vue"
      ),
    meta: {
      layout: PaymentLayout,
      allowedRoles: [Role.AUTH],
    },
  },
  //Profile
  {
    path: "/settings",
    name: "ProfileSettings",
    component: () =>
      import(
        /* webpackChunkName: "ProfileSettings" */ "../views/Profile/ProfileSettings.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/edit-profile",
    name: "ProfileEdit",
    component: () =>
      import(
        /* webpackChunkName: "ProfileEdit" */ "../views/Profile/ProfileEdit.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/change-password",
    name: "UpdatePassword",
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */ "../views/Profile/UpdatePassword.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/notification-setting",
    name: "ProfileNotification",
    component: () =>
      import(
        /* webpackChunkName: "ProfileNotification" */ "../views/Profile/ProfileNotification.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/payment-method",
    name: "PaymentMethod",
    component: () =>
      import(
        /* webpackChunkName: "PaymentMethod" */ "../views/Profile/PaymentMethod.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/add-payment",
    name: "AddPayment",
    component: () =>
      import(
        /* webpackChunkName: "AddPayment" */ "../views/Profile/AddPaymentMethod.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "Notifications" */ "../views/Dashboard/NotificationView.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },

  /* ADMIN */
  {
    path: "/get-requests",
    name: "ManageAdminRequests",
    component: GetAdminRequests,
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/request-detail/:id",
    name: "RequestsDetails",
    component: RequestDetails,
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/assign-date/:id/:quoteId",
    name: "AssignDate",
    component: () =>
      import(
        /* webpackChunkName: "AssignDate" */ "../views/Admin/Requests/AssignDate.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/confirm-pricing/:id",
    name: "ConfirmPrice",
    component: () =>
      import(
        /* webpackChunkName: "ConfirmPricing" */ "../views/Admin/Requests/Pricing/PackageIndex.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/all-packages",
    name: "AllPackages",
    component: () =>
      import(
        /* webpackChunkName: "AllPackages" */ "../views/Admin/SupportPackages/AllPackages.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/package-detail/:id",
    name: "PackageDetail",
    component: () =>
      import(
        /* webpackChunkName: "PackageDetail" */ "../views/Admin/SupportPackages/PackageDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/product-repayment/:id",
    name: "ProductRepayment",
    component: () =>
      import(
        /* webpackChunkName: "ProductRepayment" */ "../views/Admin/SupportPackages/PackageRepayments.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/all-complaints",
    name: "AllComplaints",
    component: () =>
      import(
        /* webpackChunkName: "AllComplaints" */ "../views/Admin/Complaints/IssuesIndex.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/complaint-details/:id",
    name: "ComplaintDetails",
    component: () =>
      import(
        /* webpackChunkName: "ComplaintDetails" */ "../views/Admin/Complaints/IssueDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/installer-profile",
    name: "InstallerProfile",
    component: () =>
      import(
        /* webpackChunkName: "InstallerProfile" */ "../views/Admin/Profile/ProfileSettings.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/manage-engineers",
    name: "ManageEngineers",
    component: () =>
      import(
        /* webpackChunkName: "ManageEngineers" */ "../views/Admin/Profile/ManageEngineers.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/add-engineers",
    name: "AddEngineers",
    component: () =>
      import(
        /* webpackChunkName: "ManageEngineers" */ "../views/Admin/Profile/AddEngineers.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },

  {
    path: "/identification",
    name: "KYCDocumentIdentification",
    component: () =>
      import(
        /* webpackChunkName: "KYCDocumentIdentification" */ "../views/kyc/Identity/IdentityIndex.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },

  {
    path: "/complete-liveliness/:id",
    name: "CompleteLiveliness",
    component: () =>
      import(
        /* webpackChunkName: "CompleteLiveliness" */ "../views/Dashboard/CompleteLiveliness.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },

  // {
  //   path: "/get-request-detail",
  //   name: "ManageRequestsDetails",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "RequestDetails" */ "../views/Admin/Requests/RequestDetails.vue"
  //     ),
  //   meta: {
  //     allowedRoles: [Role.AUTH],
  //   },
  // },

  /* Support */
  {
    path: "/requests",
    name: "ManageRequests",
    component: () =>
      import(
        /* webpackChunkName: "ManageRequestIndex" */ "../views/Support/Requests/RequestIndex.vue"
      ),
    meta: {
      allowedRoles: [Role.SUPPORT],
    },
  },

  {
    path: "/manage-packages",
    name: "ManagePackages",
    component: () =>
      import(
        /* webpackChunkName: "ManagePackages" */ "../views/Support/SupportPackages/AllPackages.vue"
      ),
    meta: {
      allowedRoles: [Role.SUPPORT],
    },
  },

  {
    path: "/product-detail/:id",
    name: "ProductPackages",
    component: () =>
      import(
        /* webpackChunkName: "PackageProductDetails" */ "../views/Support/SupportPackages/PackageDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.SUPPORT],
    },
  },

  {
    path: "/engineer-profile",
    name: "EngineerProfile",
    component: () =>
      import(
        /* webpackChunkName: "EngineerProfile" */ "../views/Support/Profile/ProfileSettings.vue"
      ),
    meta: {
      allowedRoles: [Role.SUPPORT],
    },
  },

  {
    path: "/engineer-profile-settings",
    name: "EngineerProfileSetting",
    component: () =>
      import(
        /* webpackChunkName: "EngineerProfileSettings" */ "../views/Support/Profile/ProfileEdit.vue"
      ),
    meta: {
      allowedRoles: [Role.SUPPORT],
    },
  },

  // complete-installation
  {
    path: "/complete-installation/:id",
    name: "CompleteInstallation",
    component: () =>
      import(
        /* webpackChunkName: "CompleteInstallation" */ "../views/Support/Requests/InstallationComplete.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },

  // Sales Agent
  {
    path: "/sales-agent",
    name: "SalesAgentOverview",
    component: SalesAuthView,
    meta: {
      allowedRoles: [Role.GUEST],
    },
  },

  {
    path: "/sales-agent/register",
    name: "SalesAgentRegister",
    component: () =>
      import(
        /* webpackChunkName: "SalesAgentRegister" */ "../views/auth/SalesAgent/IndexView.vue"
      ),
    meta: {
      layout: AuthLayout,
      allowedRoles: [Role.GUEST],
    },
  },

  {
    path: "/sales-dashboard",
    name: "SalesAgentDashboard",
    component: SalesAgentDashboard,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/sales-referrals",
    name: "SalesAgentAllReferrals",
    component: SalesAgentReferrals,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/sales-owing-referrals",
    name: "SalesAgentOwingReferrals",
    component: () =>
      import(
        /* webpackChunkName: "SalesAgentOwingReferrals" */ "../views/SalesAgent/OwingReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/sales-nonperforming-referrals",
    name: "SalesAgentNonPerformingReferrals",
    component: () =>
      import(
        /* webpackChunkName: "SalesAgentNonPerformingReferrals" */ "../views/SalesAgent/NonPerformingReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/all-sales-quotes",
    name: "SalesAgentsQuotes",
    component: () =>
      import(
        /* webpackChunkName: "SalesAgentsQuotes" */ "../views/SalesAgent/AllQuotes.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/owing-referrals",
    name: "CustomerAgentOwingReferrals",
    component: () =>
      import(
        /* webpackChunkName: "CustomerAgentOwingReferrals" */ "../views/Dashboard/Referral/OwingReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },

  {
    path: "/nonperforming-referrals",
    name: "AgentNonPerformingReferrals",
    component: () =>
      import(
        /* webpackChunkName: "CustomerAgentNonPerformingReferrals" */ "../views/Dashboard/Referral/NonPerformingReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/sales-details/:id",
    name: "SalesAgentProductDetails",
    component: SalesAgentProductDetails,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/sales-details-repayment/:id",
    name: "SalesAgentProductDetailsRepayment",
    component: SalesAgentProductDetailsRepayment,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/customer-details-repayment/:id",
    name: "CustomerAgentProductDetailsRepayment",
    component: CustomerAgentProductDetailsRepayment,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/agent-profile",
    name: "AgentProfile",
    component: () =>
      import(
        /* webpackChunkName: "AgentProfile" */ "../views/SalesAgent/Profile/ProfileSettings.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/agent-profile-settings",
    name: "AgentProfileSetting",
    component: () =>
      import(
        /* webpackChunkName: "AgentProfileSettings" */ "../views/SalesAgent/Profile/ProfileEdit.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/cordinator-profile",
    name: "CordinatorProfile",
    component: () =>
      import(
        /* webpackChunkName: "CordinatorProfile" */ "../views/SalesAgentCordinator/Profile/ProfileSettings.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/cordinator-profile-settings",
    name: "CordinatorProfileSetting",
    component: () =>
      import(
        /* webpackChunkName: "CordinatorProfileSetting" */ "../views/SalesAgentCordinator/Profile/ProfileEdit.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/agent-packages",
    name: "AgentPackages",
    component: () =>
      import(
        /* webpackChunkName: "AgentPackages" */ "../views/SalesAgent/SupportPackages/AllPackages.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/agent-package-detail/:id",
    name: "PackagePackages",
    component: () =>
      import(
        /* webpackChunkName: "PackageProductDetails" */ "../views/SalesAgent/SupportPackages/PackageDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.SUPPORT],
    },
  },

  {
    path: "/agent-package-repayment/:id",
    name: "AgentPackageRepayment",
    component: () =>
      import(
        /* webpackChunkName: "AgentPackageRepayment" */ "../views/SalesAgent/SupportPackages/PackageRepayments.vue"
      ),
    meta: {
      allowedRoles: [Role.ADMIN],
    },
  },
  {
    path: "/agent-customer-edit-quote",
    name: "EditAgentQuote",
    component: AgentQuoteIndex,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/cordinator-customer-edit-quote",
    name: "EditCordinatorQuote",
    component: CordinatorQuoteIndex,
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/agent-customer-quote/:id",
    name: "AgentCustomerQuote",
    component: () =>
      import(
        /* webpackChunkName: "AgentCustomerQuote" */ "../views/SalesAgent/QuoteDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/generate-component",
    name: "AgentGenerateComponentPrice",
    component: () =>
      import(
        /* webpackChunkName: "AgentGenerateComponentPrice" */ "../views/SalesAgent/SupportPackages/GenerateComponentPrice.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  // State cordinators
  {
    path: "/cordinator-dashboard",
    name: "SalesAgentCordinatorDashboard",
    component: () =>
      import(
        /* webpackChunkName: "SalesAgentCordinatorDashboard" */ "../views/SalesAgentCordinator/Dashboard.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/all-cordinator-agents",
    name: "SalesAgentCordinatorAgents",
    component: () =>
      import(
        /* webpackChunkName: "SalesAgentCordinatorAgents" */ "../views/SalesAgentCordinator/AllAgents.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/add-cordinator-agent",
    name: "AddSalesAgentCordinatorAgents",
    component: () =>
      import(
        /* webpackChunkName: "AddSalesAgentCordinatorAgents" */ "../views/SalesAgentCordinator/AddAgents.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/all-coordinator-referrals",
    name: "CoordinatorReferrals",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorReferrals" */ "../views/SalesAgentCordinator/AllReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/coordinator-owing-referrals",
    name: "CoordinatorOwingReferrals",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorOwingReferrals" */ "../views/SalesAgentCordinator/OwingReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/coordinator-nonperforming-referrals",
    name: "CoordinatorNonPerformingReferrals",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorNonPerformingReferrals" */ "../views/SalesAgentCordinator/NonPerformingReferrals.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/coordinator-details/:id",
    name: "CoordinatorProductDetails",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorProductDetails" */ "../views/SalesAgentCordinator/Details.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/coordinator-customer-quote/:id",
    name: "CoordinatorCustomerQuote",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorCustomerQuote" */ "../views/SalesAgentCordinator/QuoteDetails.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/all-cordinator-agents-quote/:id",
    name: "CoordinatorAgentCustomerQuotes",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorAgentCustomerQuotes" */ "../views/SalesAgentCordinator/SalesAgentQuotes.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/coordinator-all-products",
    name: "CoordinatorAllProductPackages",
    component: () =>
      import(
        /* webpackChunkName: "CoordinatorAllProductPackages" */ "../views/SalesAgentCordinator/SupportPackages/AllPackages.vue"
      ),
    meta: {
      allowedRoles: [Role.SALES_AGENT],
    },
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      allowedRoles: [Role.AUTH],
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    // if (savedPosition) return savedPosition;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // return {
    //   top: 0,
    //   behavior: "smooth",
    // };
  },
});

router.afterEach((to, _from, failure) => {
  if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
    window.location.reload();
    return;
  }
});

router.beforeEach((to: any, _from, next) => {
  const userObject = SessionStorageService.decodeToken();
  // console.log(userObject);

  if (!to.meta?.allowedRoles) return next();

  if (to.meta.allowedRoles.includes(Role.AUTH)) {
    if (!userObject) {
      // next({ name: "Login" });
      next({ name: "Login", query: { redirect: to.path } });
    } else {
      next();
    }
    return;
  }

  if (to.meta.allowedRoles.includes(Role.SUPPORT)) {
    if (!userObject) {
      next({ name: "Login", query: { redirect: to.path } });
    } else {
      next();
    }
    return;
  }

  if (to.meta.allowedRoles.includes(Role.ADMIN)) {
    if (!userObject) {
      next({ name: "Login", query: { redirect: to.path } });
    } else {
      next();
    }
    return;
  }

  if (to.meta.allowedRoles.includes(Role.SALES_AGENT)) {
    if (!userObject) {
      next({ name: "Login", query: { redirect: to.path } });
    } else {
      next();
    }
    return;
  }

  if (to.meta.allowedRoles.includes(Role.GUEST)) {
    if (!userObject) {
      next();
    } else {
      next({ name: "Dashboard" });
    }
    return;
  }
});

router.onError((error) => {
  if (/ChunkLoadError:.*failed chunk/i.test(error.message)) {
    window.location.reload();
  } else if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
